import React, { useState } from "react";
import DragAndDrop from "../../../../../UI/DragAndDrop/DragAndDrop";
import Quick from "../../FileCardButtons/Quick";
import Advanced from "../../FileCardButtons/Advanced/Advanced";
import StaticSubmit from "../../FileCardButtons/StaticSubmit/StaticSubmit";
import Mobile from "../../FileCardButtons/Mobile/Mobile";

const Submission = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  return (
    <div>
      <DragAndDrop selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
      <div className="flex m-t-20 m-b-20">
        <Quick selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
        <Advanced selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
        <StaticSubmit selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
        {/* <Mobile selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} /> */}
      </div>
    </div>
  );
};

export default Submission;
