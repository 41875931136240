import React from "react";

import "./styles.scss";

const Input = ({ onChange, value, placeholder, type = "", name = "" }) => {
  return (
    <input
      className="input-field"
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      type={type}
      name={name}
    />
  );
};

export default Input;
