import { combineReducers } from "redux";
import usersSlice from "./slices/usersSlice";
import themeSlice from "./slices/themeSlice";
import dashboardSlice from "./slices/dashboardSlice";
import analysisReportsSlice from "./slices/analysisReportsSlice";
import notifierSlice from "./slices/notifierSlice";
import integrationsSlice from "./slices/integrationsSlice";
import settingsSlice from "./slices/settingsSlice";
import networkDefenderSlice from "./slices/networkDefenderSlice";
import healthCheckSlice from "./slices/healthCheckSlice";
import mailGatewaySlice from "./slices/mailGatewaySlice";

const rootReducer = combineReducers({
  users: usersSlice.reducer,
  theme: themeSlice.reducer,
  dashboard: dashboardSlice.reducer,
  analysisReports: analysisReportsSlice.reducer,
  notifier: notifierSlice.reducer,
  integrations: integrationsSlice.reducer,
  settings: settingsSlice.reducer,
  networkDefender: networkDefenderSlice.reducer,
  healthCheck: healthCheckSlice.reducer,
  mailGateway: mailGatewaySlice.reducer,
});

export default rootReducer;
