import React from "react";
import { DETAILS_DROPPED_FILE_LIST_COLUMN_NAME } from "../../../../../utils/constants/detailsKey";

import "./styles.scss";
import Verdict from "../../../Cells/Verdict/Verdict";

const getLastTwoSegments = (path) => {
  const segments = path.split("/");
  const result = segments.slice(-2).join("/");
  return result;
};

const Details = ({ currentFile }) => {
  return (
    <div className="details-block">
      {DETAILS_DROPPED_FILE_LIST_COLUMN_NAME.map(({ name, accessor }, index) => (
        <div className="details-block__row" key={index}>
          <p className="details-block__row-item">{name}</p>
          {accessor === "verdict" ? (
            <Verdict verdict={currentFile[accessor]} />
          ) : (
            <p className="details-block__row-item">
              {accessor === "path"
                ? getLastTwoSegments(currentFile[accessor])
                : currentFile[accessor]
                ? currentFile[accessor]
                : "-"}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Details;
