import React, { useState } from "react";
import CardForm from "../../../../Components/common/CardForm/CardForm";
import TextField from "../../../../Components/common/TextField/TextField";
import { Link } from "react-router-dom";
import { forgotPasswordRequest } from "../../../../store/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "../../../../Components/UI/Backdrop/Backdrop";
import ReCAPTCHA from "react-google-recaptcha";

const EmailSend = ({ email, setEmail, setTabs }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.users.loginLoading);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleClick = () => {
    if (!captchaToken) {
      alert("Please complete the CAPTCHA verification.");
      return;
    }
    dispatch(forgotPasswordRequest({ email }));
    setTabs("check-email");
  };

  return (
    <CardForm
      title="Did you forget your password?"
      subTitle="Please contact our CyberFortress support team.">
      <TextField
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        labelText="Email"
        name="email"
        required={true}
      />
      <ReCAPTCHA
        sitekey="6Lc6dZAqAAAAAFuTi4WNoanRNlV7SnXBKl9sjQwk"
        onChange={handleCaptchaChange}
        theme="dark"
      />

      <button className="card__form-btn" onClick={() => handleClick()}>
        Reset Password
      </button>

      <p style={{ textAlign: "center", marginTop: "100px" }}>
        Go back to <Link to="/login">Login</Link>
      </p>
      <p style={{ textAlign: "center" }}>
        New? <Link to="/signup">Join Us</Link>
      </p>
      {loading && <Backdrop show={loading} />}
    </CardForm>
  );
};

export default EmailSend;
