import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SignIn from "./Pages/SignIn/SignIn";
import Layout from "./Components/UI/Layout/Layout";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import { useTheme } from "./utils/hook/useTheme";
import { loadFromLocalStorage } from "./utils/localStorage";
import AnalysisReportsWrapper from "./Pages/AnalysisReports/AnalysisReportsWrapper";
import RemoteConnection from "./Pages/RemoteConnection/RemoteConnection";
import ReportExport from "./Pages/ReportExport/ReportExport";
import ReportPDF from "./Components/PagesComponents/ReportExport/ReportPDF/ReportPDF";
import { themeRequest } from "./store/actions/themeAction";
import Reports from "./Pages/Reports/Reports";
import Notifier from "./Components/common/Notifier/Notifier";
import { CssBaseline } from "@mui/material";
import Settings from "./Pages/Settings/Settings";
import CustomScrollbar from "./Components/common/CustomScrollbar/CustomScrollbar";
import "./styles/main.scss";
import RunningAnalysis from "./Pages/RunningAnalysis/RunningAnalysis";
import UserProfile from "./Pages/UserProfile/UserProfile";
import ImapEmail from "./Pages/IntegratedAnalysis/ImapEmail/ImapEmail";
import Statistics from "./Pages/Statistics/Statistics";
import FileShare from "./Pages/IntegratedAnalysis/FileShare/FileShare";
import ProxyWeb from "./Pages/IntegratedAnalysis/ICAPweb/Icap";
import Endpoint from "./Pages/IntegratedAnalysis/Endpoint/Endpoint";
import WinShare from "./Pages/IntegratedAnalysis/WinShare/WinShare";
import PostfixEmail from "./Pages/IntegratedAnalysis/PostfixEmail/PostfixEmail";
import Email from "./Pages/IntegratedAnalysis/UsbTransfer/UsbTransfer";
import { licenceCheckRequest } from "./store/actions/usersActions";
import SystemInfo from "./Pages/Licence/SystemInfo";
import IntegrationMarket from "./Pages/IntegrationMarket/IntegrationMarket";
import Footer from "./Components/UI/Footer/Footer";
import ErrorBoundary from "./Pages/ErrorPage/ErrorBoundary";
import UserAccounts from "./Pages/Settings/Content/Tabs/UserAccounts/UserAccounts";
import DataRetention from "./Pages/Settings/Content/Tabs/DataRetention/DataRetention";
import DumpAndRestore from "./Pages/Settings/Content/Tabs/DumpAndRestore/DumpAndRestore";
import Mailing from "./Pages/Settings/Content/Tabs/Mailing/Mailing";
import Syslog from "./Pages/Settings/Content/Tabs/Syslog/Syslog";
import PublicApi from "./Pages/Settings/Content/Tabs/PublicApi/PublicApi";
import AgentStatus from "./Pages/IntegratedAnalysis/AgentStatus/AgentStatus";
import OutlookAddIn from "./Pages/IntegratedAnalysis/OutlookAddIn/OutlookAddIn";
import SocialNotification from "./Pages/Settings/Content/Tabs/SocialNotification/SocialNotification";
import NetworkDetections from "./Pages/IntegratedAnalysis/NetworkDetections/NetworkDetections";
import Urls from "./Pages/IOCs/Urls/Urls";
import DomainsAndIps from "./Pages/IOCs/DomainsAndIps/DomainsAndIps";
import AnalyzedEmails from "./Pages/MailGateway/AnalyzedEmails";
import BlockedEmails from "./Pages/MailGateway/BlockedEmails";
import Products from "./Pages/Products/Products";
import CloudLayout from "./CloudModel/CloudUI/CloudLayout/CloudLayout";
import CloudFooter from "./CloudModel/CloudUI/CloudFooter/CloudFooter";
import Login from "./CloudModel/CloudPages/Login/Login";
import SignUp from "./CloudModel/CloudPages/SignUp/SignUp";
import ForgotPW from "./CloudModel/CloudPages/ForgotPW/ForgotPW";
import Home from "./CloudModel/CloudPages/Home/Home";
import ApiDocu from "./Components/UI/ApiDocu/ApiDocu";

const ProtectedRoute = ({ isAllowed, redirectTo, component: Component, ...props }) => {
  return isAllowed ? (
    <Route {...props} render={(routeProps) => <Component {...routeProps} />} />
  ) : (
    <Redirect to={redirectTo} />
  );
};

const AppProcess = () => {
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);
  const user = useSelector((state) => state?.users.user);
  const [currentComponent, setCurrentComponent] = useState(null);

  useEffect(() => {
    if (
      licenceCheck?.theme !== "cloud_model" ||
      user?.role === "ADMIN" ||
      user?.role === "SUPERADMIN"
    ) {
      setCurrentComponent(<AppProcessLocal />);
    } else {
      setCurrentComponent(<AppProcessCloud />);
    }
  }, [licenceCheck, user]);

  return currentComponent;
};

const AppProcessLocal = () => {
  const user = useSelector((state) => state?.users.user);
  const { theme } = useTheme();

  return (
    <ErrorBoundary>
      <CustomScrollbar>
        <CssBaseline />
        {theme || !user ? (
          <div>
            <Switch>
              <ProtectedRoute
                path="/"
                exact
                component={SignIn}
                isAllowed={!user}
                redirectTo="/dashboard"
              />
              <ProtectedRoute
                path="/login"
                component={SignIn}
                isAllowed={user}
                redirectTo="/dashboard"
              />
              <ProtectedRoute
                path="/forgot-password"
                component={ForgotPassword}
                isAllowed={!user}
                redirectTo="/dashboard"
              />
              <ProtectedRoute
                path="/reset-password/:token"
                component={ResetPassword}
                isAllowed={!user}
                redirectTo="/dashboard"
              />
              <Layout>
                <ProtectedRoute
                  path="/docs/api"
                  exact
                  component={ApiDocu}
                  isAllowed={user}
                  redirectTo="/dashboard"
                />
                <ProtectedRoute
                  path="/dashboard"
                  exact
                  component={Dashboard}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/historical-analysis"
                  exact
                  component={RunningAnalysis}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/statistics"
                  exact
                  component={Statistics}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/analysis-reports"
                  component={AnalysisReportsWrapper}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/report-export"
                  component={ReportExport}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/report-pdf"
                  component={ReportPDF}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/remote-connection"
                  component={RemoteConnection}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/reports"
                  component={Reports}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/settings/user-accounts"
                  component={UserAccounts}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/settings/data-retention"
                  component={DataRetention}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/settings/backup-and-restore"
                  component={DumpAndRestore}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/settings/mail-notifications"
                  component={Mailing}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/settings/syslog"
                  component={Syslog}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/settings/publicapi"
                  component={PublicApi}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/settings/socialnotification"
                  component={SocialNotification}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/system-info"
                  component={SystemInfo}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/integration-market"
                  component={IntegrationMarket}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/products"
                  component={Products}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/user-profile"
                  component={UserProfile}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/ImapEmail"
                  component={ImapEmail}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/sharepoint"
                  component={FileShare}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/proxy"
                  component={ProxyWeb}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/submissions"
                  component={Endpoint}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/fileshare"
                  component={WinShare}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/postfixemail"
                  component={PostfixEmail}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/outlook"
                  component={OutlookAddIn}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/usbtransfer"
                  component={Email}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/agentstatus"
                  component={AgentStatus}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/network-detections"
                  component={NetworkDetections}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/iocs/urls"
                  component={Urls}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/iocs/hosts-and-domains"
                  component={DomainsAndIps}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/mailgateway/analyzed-emails"
                  component={AnalyzedEmails}
                  isAllowed={user}
                  redirectTo="/"
                />
                <ProtectedRoute
                  path="/mailgateway/blocked-emails"
                  component={BlockedEmails}
                  isAllowed={user}
                  redirectTo="/"
                />
              </Layout>
            </Switch>
          </div>
        ) : (
          <div className="Backdrop" style={{ backgroundColor: "black" }}>
            <div className="loaderdefault" />
          </div>
        )}

        <Footer />
        <Notifier />
      </CustomScrollbar>
    </ErrorBoundary>
  );
};

const AppProcessCloud = () => {
  const user = useSelector((state) => state?.users.user);

  return (
    <ErrorBoundary>
      <CustomScrollbar>
        <CssBaseline />
        <Switch>
          <ProtectedRoute path="/login" component={Login} isAllowed={!user} redirectTo="/" />
          <ProtectedRoute path="/signup" component={SignUp} isAllowed={!user} redirectTo="/" />

          <ProtectedRoute
            path="/forgot-password"
            component={ForgotPW}
            isAllowed={!user}
            redirectTo="/"
          />
          <CloudLayout>
            <ProtectedRoute path="/" exact component={Home} isAllowed={true} />
            <ProtectedRoute
              path="/reset-password/:token"
              component={ResetPassword}
              isAllowed={!user}
              redirectTo="/"
            />
            <ProtectedRoute
              path="/docs/api"
              exact
              component={ApiDocu}
              isAllowed={user}
              redirectTo="/dashboard"
            />
            <ProtectedRoute
              path="/analysis-reports"
              component={AnalysisReportsWrapper}
              isAllowed={user}
              redirectTo="/"
            />
            <ProtectedRoute
              path="/report-export"
              component={ReportExport}
              isAllowed={user}
              redirectTo="/"
            />
            <ProtectedRoute
              path="/report-pdf"
              component={ReportPDF}
              isAllowed={user}
              redirectTo="/"
            />
            <ProtectedRoute path="/reports" component={Reports} isAllowed={user} redirectTo="/" />
            <ProtectedRoute
              path="/user-profile"
              component={UserProfile}
              isAllowed={user}
              redirectTo="/"
            />
          </CloudLayout>
        </Switch>
        <CloudFooter />
        <Notifier />
      </CustomScrollbar>
    </ErrorBoundary>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    setTheme(loadFromLocalStorage("theme"));
    dispatch(themeRequest(theme));
    dispatch(licenceCheckRequest());
  }, [dispatch]);

  return <AppProcess />;
};

export default App;
