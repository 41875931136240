import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ExpandOrShrinkIcon from "../../../Components/UI/DropDown/ExpandOrShrinkIcon";

import { RequestDistinctLiveHosts } from "../../../store/actions/mailGatewayActions";

const Dropdown = ({ title, selectedItem, items, onItemSelect }) => {
  const [open, setOpen] = useState(true);
  return (
    <div className="dropdown">
      <div className="dropdown__title" onClick={() => setOpen((state) => !state)}>
        {title}({items?.length}) <ExpandOrShrinkIcon expanded={open} collapsed={false} />
      </div>
      {open && items.length > 0 ? (
        <div className="dropdown__items">
          {items.map((item, i) => (
            <div className="dropdown__item" onClick={() => onItemSelect(title, item)} key={i}>
              <p className="dropdown__item__name">{item}</p>
              {selectedItem === item ? <div className="dropdown__item__active" /> : null}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const ProductsSidebar = ({ onItemSelect, selectedHost }) => {
  const mailGatewayHosts = useSelector((state) => state.mailGateway.distinctLiveHosts);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(RequestDistinctLiveHosts());
  }, [dispatch]);

  useEffect(() => {
    if (mailGatewayHosts.length > 0 && !selectedHost) {
      onItemSelect("Mail Gateway", mailGatewayHosts[0]);
    }
  }, [mailGatewayHosts, selectedHost, onItemSelect]);

  return (
    <div className="products-sidebar">
      <Dropdown
        title="Mail Gateway"
        selectedItem={selectedHost}
        items={mailGatewayHosts}
        onItemSelect={onItemSelect}
      />
    </div>
  );
};

export default ProductsSidebar;
