import React from "react";

const ApiDocu = () => {
  const domain = window.location.hostname;

  return (
    <iframe
      src={`https://cyber-fortress.com/docs/api/v1/index.php?domain=${domain}`}
      title="API Documentation"
      width="100%"
      height="100%"
      style={{ border: "none" }}
    />
  );
};

export default ApiDocu;
