import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import CustomSelect from "../../Components/common/CustomSelect/CustomSelect";
import CustomTable from "../../Components/common/Table/CustomTable";
import ListPopup from "../../Components/PagesComponents/Dashboard/ListPopup/ListPopup";

import { blockedMailsHeader } from "../../utils/constants/tableHeaders";
import { RequestBlockedEmails, RequestDistinctHosts } from "../../store/actions/mailGatewayActions";

import "./styles.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Details = ({ row, history }) => {
  const submissions = row?.original?.files || [];
  const detail = row?.original?.detail || "";

  const seeReportHandler = (groupID) => {
    history.push(`/mailgateway/blocked-emails?data=${groupID}&table=blocked-emails`);
  };

  if (detail) {
    return (
      <div className="blocked-emails__table__details">
        <p>{detail}</p>
      </div>
    );
  }

  return (
    <div className="blocked-emails__table__submissions">
      {submissions.map((submission, i) => (
        <div key={i} className="blocked-emails__table__submission">
          <p title={submission.file_name}>
            <span>{submission.api_resp} file found: </span>
            {submission.file_name}
          </p>
          <p>
            <span>File Hash: </span>
            {submission?.file_hash || "-"}
          </p>
          <button
            disabled={submission.group_id === "-"}
            onClick={() => seeReportHandler(submission.group_id)}>
            See Report
          </button>
        </div>
      ))}
    </div>
  );
};

const BlockedEmails = () => {
  const [selectedHost, setSelectedHost] = useState(null);
  const [listPopupActive, setListPopupActive] = useState(false);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(null);

  const dispatch = useDispatch();

  const distinctHosts = useSelector((state) => state.mailGateway.distinctHosts);
  const distinctHostsLoading = useSelector((state) => state.mailGateway.distinctHostsLoading);
  const blockedEmails = useSelector((state) => state.mailGateway.blockedEmails);

  let query = useQuery();

  useEffect(() => {
    if (query.get("data") !== null) {
      setListPopupActive(true);
    } else {
      setListPopupActive(false);
    }
  }, [query]);

  useEffect(() => {
    dispatch(RequestDistinctHosts());
  }, [dispatch]);

  useEffect(() => {
    if (selectedHost?.id && selectedTimeFilter) {
      dispatch(RequestBlockedEmails({ host: selectedHost.id, timeFilter: selectedTimeFilter }));
    }
  }, [selectedHost, selectedTimeFilter]);

  return (
    <div className="blocked-emails">
      <div className="blocked-emails__header">
        <h1>Blocked Emails</h1>
        <p>Below list is displaying blocked emails of selected host:</p>
        <div className="blocked-emails__header__select">
          <p>Select host: </p>
          <CustomSelect
            data={distinctHosts.length ? distinctHosts : []}
            select={selectedHost}
            setSelected={setSelectedHost}
            width={220}
            disabled={distinctHosts.length === 0}
            placeholder={distinctHostsLoading ? "Fetching data..." : "No data."}
          />
        </div>
      </div>
      <div className="blocked-emails__table border">
        <CustomTable
          leftAction={true}
          renderRowSubComponent={Details}
          exportFile={true}
          dataTable={blockedEmails}
          tableName="blockedEmails"
          column={blockedMailsHeader}
          paginationButtons={true}
          className=""
          customPageSize={10}
          minHeight="585px"
          minBottom="5px"
          defaultPosition="relative"
          selectedHost={selectedHost?.id}
          filterByTime={true}
          selectedTimeFilter={selectedTimeFilter}
          setSelectedTimeFilter={setSelectedTimeFilter}
          defaultFilterIndex={2}
        />
      </div>
      <ListPopup
        onClickOk={() => setListPopupActive(false)}
        show={listPopupActive}
        groupID={query.get("data")}
        tableType={query.get("table")}
        tableType2="blocked-emails"
      />
    </div>
  );
};

export default BlockedEmails;
