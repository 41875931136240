const MailGatewayIcon = ({ width = 21, heigth = 21, color = "var(--text-color-card)" }) => {
  return (
    <svg
      id="Layer_1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width={width}
      height={heigth}>
      <path
        fill={color}
        d="m3 5v-1a4 4 0 0 1 4-4h1.47a2.992 2.992 0 0 1 1.7.528l1.885 1.3a1 1 0 0 0 .566.172h4.379a4 4 0 0 1 3.858 3zm20 15h-8.184a3 3 0 0 0 -1.816-1.816v-3.184h4a4 4 0 0 0 4-4v-4h-18v4a4 4 0 0 0 4 4h4v3.184a3 3 0 0 0 -1.816 1.816h-8.184a1 1 0 0 0 0 2h8.184a2.982 2.982 0 0 0 5.632 0h8.184a1 1 0 0 0 0-2z"
      />
    </svg>
  );
};

export default MailGatewayIcon;
