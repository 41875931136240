import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Field from "../../Settings/Content/Components/Field";
import TrashBucketIcon from "../../../Components/Icon-SVG/TrashBucketIcon";

import { addNotification } from "../../../store/actions/notifierActions";
import {
  RequestGetWhitelistSettings,
  RequestSetWhitelistSettings,
} from "../../../store/actions/mailGatewayActions";

const WhitelistForm = ({ selectedHost }) => {
  const dispatch = useDispatch();

  const currentSettings = useSelector((state) => state.mailGateway.whitelistSettings);

  const [settings, setSettings] = useState({
    senderEmails: [],
    newSenderEmail: "",
  });

  useEffect(() => {
    if (selectedHost) {
      dispatch(RequestGetWhitelistSettings({ hostname: selectedHost }));
    }
  }, [selectedHost, dispatch]);

  useEffect(() => {
    if (currentSettings && selectedHost) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        ...currentSettings,
      }));
    }
  }, [currentSettings, selectedHost]);

  const handleSave = () => {
    if (selectedHost) {
      dispatch(RequestSetWhitelistSettings({ hostname: selectedHost, settings }));
    }
  };

  const handleAddSenderEmail = () => {
    const trimmed = settings.newSenderEmail.trim();
    if (trimmed !== "" && trimmed.includes("@") && trimmed.split("@")[1] !== "") {
      setSettings((prevSettings) => ({
        ...prevSettings,
        senderEmails: [...prevSettings.senderEmails, settings.newSenderEmail.trim()],
        newSenderEmail: "",
      }));
    } else {
      dispatch(
        addNotification({
          message: "Enter a valid email address (example@example.com)",
          options: { variant: "error" },
          time: 3000,
        }),
      );
    }
  };

  const handleRemoveSenderEmail = (index) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      senderEmails: prevSettings.senderEmails.filter((_, i) => i !== index),
    }));
  };

  return (
    <form className="products-body-form" onSubmit={(e) => e.preventDefault()}>
      <div className="products-body-form__line">
        <Field
          label="Sender Emails"
          width="300px"
          value={settings.newSenderEmail}
          onChange={(e) => setSettings((prev) => ({ ...prev, newSenderEmail: e.target.value }))}
          parentClassName="p-r-20"
          placeholder="Enter email address (e.g., example@exa.mp)"
          name="newSenderEmail"
          type="email"
        />
        <button type="button" className="products-body-form__button" onClick={handleAddSenderEmail}>
          +
        </button>
      </div>
      <ul className="sender-emails-list" style={{ gridColumn: "span 2" }}>
        {settings.senderEmails?.map((email, index) => (
          <li key={index} className="sender-email-item">
            {email}
            <button
              type="button"
              style={{ display: "flex" }}
              onClick={() => handleRemoveSenderEmail(index)}>
              <TrashBucketIcon width="16" height="16" />
              <p style={{ marginLeft: "5px" }}>Delete</p>
            </button>
          </li>
        ))}
      </ul>
      <button type="button" className="save-button primary-button" onClick={handleSave}>
        Save Settings
      </button>
    </form>
  );
};

export default WhitelistForm;
