import Input from "../Input/Input";
import ToggleButton from "../../ToggleButton/ToggleButton";
import { artifactAnalysisOptions, networkTrafficOptions, typeOptions } from "../constants";

import "../styles.scss";

const Content = ({
  currentFileIndex,
  activeOs,
  selectedFiles,
  onCheckboxInputChange = () => {},
  onEnforceRuntimeChange = () => {},
  onOtherInputsChange = () => {},
}) => {
  const currentFile = selectedFiles[currentFileIndex][activeOs];
  return (
    <div>
      <section
        className="submit-popup-container__type"
        style={{ marginTop: "5px", marginBottom: "5px" }}>
        <p className="light margin-top">Type</p>
        {activeOs === "Linux" ? (
          <p className="submit-popup-container__type--labels">
            Automatic Evasive Analysis selected
          </p>
        ) : (
          <div className="submit-popup-container__type--labels">
            {typeOptions.map((option) => (
              <label key={option.value} className="align-items">
                <input
                  type="checkbox"
                  value={option.value}
                  name="type"
                  className="submit-popup-container__memory-dump--input"
                  onChange={onCheckboxInputChange}
                  checked={currentFile.type.includes(option.value)}
                />
                <p className="margin-left-12">{option.display}</p>
              </label>
            ))}
          </div>
        )}
        <div
          className="submit-popup-container__action-script__inputs"
          style={{ marginTop: "10px" }}>
          <div>
            <p className="light margin-top">Command Line</p>
            <Input
              onChange={onOtherInputsChange}
              value={currentFile.command_line}
              placeholder="e.g /r /t"
              name="command_line"
            />
          </div>
          <div>
            <p className="light margin-top">Environmental Variable</p>
            <Input
              onChange={onOtherInputsChange}
              value={currentFile.enviromental_variable}
              placeholder="e.g ALLPROFILE=C:\Data"
              name="enviromental_variable"
            />
          </div>
        </div>
        <div className="submit-popup-container__action-script__inputs">
          <div>
            <p className="light margin-top-14">Related Passwords</p>
            <Input
              onChange={onOtherInputsChange}
              value={currentFile.related_password}
              placeholder="e.g abv123.test123"
              name="related_password"
            />
          </div>
          <div>
            <p className="light margin-top-14">Runtime Duration</p>
            <div className="submit-popup-container__range-container">
              <div className="submit-popup-container__range-container__data">
                <div></div>
                <div>{currentFile.runtime_duration}</div>
                <div></div>
              </div>
              <input
                type="range"
                max="360"
                name="runtime_duration"
                onChange={onOtherInputsChange}
                value={currentFile.runtime_duration}
              />
            </div>
          </div>
        </div>
        <div className="submit-popup-container__action-script__enforce">
          <div className="submit-popup-container__action-script__selector">
            <p className="light">Action Script</p>
            <select
              name="action_script"
              id="standard-select"
              value={currentFile.action_script}
              onChange={onOtherInputsChange}>
              <option value="default">Default</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
            </select>
            <p className="bleached font-size-12">Runtime action script</p>
          </div>
          <div className="margin-top-bottom-8" style={{ marginLeft: "85px" }}>
            <p className="light">Enforce Runtime</p>
            <div className="submit-popup-container__action-script__enforce--toggle">
              <ToggleButton
                active={currentFile.enforse_runtime}
                rightText={currentFile.enforse_runtime ? "Enable" : "Disable"}
                onClick={onEnforceRuntimeChange}
              />
            </div>
            <p className="bleached font-size-12">Force VM to fit above time</p>
          </div>
        </div>
      </section>
      <section className="submit-popup-container__memory-dump">
        <p className="light margin-top">Artifact Analysis</p>
        <div className="submit-popup-container__memory-dump--labels">
          {artifactAnalysisOptions.map((option) => (
            <label key={option.value} className="align-items">
              <input
                type="radio"
                value={option.value}
                name="memory_dump"
                className="submit-popup-container__memory-dump--input"
                checked={currentFile.memory_dump === option.value}
                onChange={onOtherInputsChange}
              />
              <p className="margin-left-12">{option.display}</p>
            </label>
          ))}
        </div>
      </section>
      <section className="submit-popup-container__network-traffic">
        <p className="light margin-top">Network Traffic</p>
        <div className="submit-popup-container__network-traffic--labels margin-top-bottom-8">
          {networkTrafficOptions.map((option) => (
            <div key={option.value} className="align-items">
              <input
                type="checkbox"
                id={option.value}
                value={option.value}
                name="network_traffic"
                className="submit-popup-container__network-traffic--labels--input"
                onChange={onCheckboxInputChange}
                checked={currentFile.network_traffic.includes(option.value)}
              />
              <label htmlFor={option.value}>
                <p className="margin-left-12">{option.display}</p>
              </label>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
export default Content;
