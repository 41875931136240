import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  RequestGetMailTransferSettings,
  RequestSetMailTransferSettings,
} from "../../../store/actions/mailGatewayActions";
import Tooltip from "../../../Components/common/Tooltip/Tooltip";
import Field from "../../Settings/Content/Components/Field";
import TrashBucketIcon from "../../../Components/Icon-SVG/TrashBucketIcon";

const MailTransferForm = ({ selectedHost }) => {
  const dispatch = useDispatch();

  const currentSettings = useSelector((state) => state.mailGateway.mailTransferSettings);

  const [settings, setSettings] = useState({
    passthrough: "",
    informUser: "",
    recvRetries: "",
    sendRetries: "",
    mtaPort: "",
    allowedClientIps: [],
    rcptDomains: [],
    newClientIp: "",
    newDomain: { domain: "", relay: "" },
  });

  useEffect(() => {
    if (selectedHost) {
      dispatch(RequestGetMailTransferSettings({ hostname: selectedHost }));
    }
  }, [selectedHost, dispatch]);

  useEffect(() => {
    if (currentSettings && selectedHost) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        ...currentSettings,
      }));
    }
  }, [currentSettings, selectedHost]);

  const handleSave = () => {
    if (selectedHost) {
      try {
        dispatch(RequestSetMailTransferSettings({ hostname: selectedHost, settings }));
      } catch (error) {
        console.error("Failed to save settings", error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleAddClientIp = () => {
    if (settings.newClientIp.trim() !== "") {
      setSettings((prevSettings) => ({
        ...prevSettings,
        allowedClientIps: [...prevSettings.allowedClientIps, settings.newClientIp.trim()],
        newClientIp: "",
      }));
    }
  };

  const handleRemoveClientIp = (index) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      allowedClientIps: prevSettings.allowedClientIps.filter((_, i) => i !== index),
    }));
  };

  const handleAddDomain = () => {
    if (settings.newDomain.domain.trim() !== "" && settings.newDomain.relay.trim() !== "") {
      setSettings((prevSettings) => ({
        ...prevSettings,
        rcptDomains: [...prevSettings.rcptDomains, settings.newDomain],
        newDomain: { domain: "", relay: "" },
      }));
    }
  };

  const handleRemoveDomain = (index) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      rcptDomains: prevSettings.rcptDomains.filter((_, i) => i !== index),
    }));
  };

  return (
    <form className="products-body-form">
      <div className="products-body-form__line">
        <div className="mailing__content__security m-b-20">
          <p className="light m-b-10 mailing__content__security__header">
            Passthrough
            <Tooltip
              text={
                "If enabled, your emails will not be blocked before, during and after processing."
              }
            />
          </p>
          <div className="mailing__content__security__labels">
            <label className="align-items">
              <input
                className="mailing__content__security__input"
                type="radio"
                value="1"
                name="passthrough"
                checked={settings.passthrough === "1"}
                onChange={handleInputChange}
              />
              <p className="margin-left-12">Enabled</p>
            </label>
            <label className="align-items">
              <input
                className="mailing__content__security__input"
                type="radio"
                value="0"
                name="passthrough"
                checked={settings.passthrough === "0"}
                onChange={handleInputChange}
              />
              <p className="margin-left-12">Disabled</p>
            </label>
          </div>
        </div>
        <div className="mailing__content__security m-b-20">
          <p className="light m-b-10 mailing__content__security__header">
            Inform User
            <Tooltip
              text={"If enabled, user will be informed in case of an email is blocked via email."}
            />
          </p>
          <div className="mailing__content__security__labels">
            <label className="align-items">
              <input
                className="mailing__content__security__input"
                type="radio"
                value="1"
                name="informUser"
                checked={settings.informUser === "1"}
                onChange={handleInputChange}
              />
              <p className="margin-left-12">Enabled</p>
            </label>
            <label className="align-items">
              <input
                className="mailing__content__security__input"
                type="radio"
                value="0"
                name="informUser"
                checked={settings.informUser === "0"}
                onChange={handleInputChange}
              />
              <p className="margin-left-12">Disabled</p>
            </label>
          </div>
        </div>
      </div>
      <div className="products-body-form__line">
        <Field
          label="Receive Retries"
          width="220px"
          value={settings.recvRetries}
          onChange={(e) =>
            handleInputChange({ target: { name: "recvRetries", value: e.target.value } })
          }
          parentClassName="p-r-20"
          placeholder="Enter retries"
          name="recvRetries"
        />
        <Field
          label="Send Retries"
          width="220px"
          value={settings.sendRetries}
          onChange={(e) =>
            handleInputChange({ target: { name: "sendRetries", value: e.target.value } })
          }
          parentClassName="p-r-20"
          placeholder="Enter retries"
          name="sendRetries"
        />
      </div>
      <div className="products-body-form__line">
        <Field
          label="MTA Port"
          width="220px"
          value={settings.mtaPort}
          onChange={(e) =>
            handleInputChange({ target: { name: "mtaPort", value: e.target.value } })
          }
          parentClassName="p-r-20"
          placeholder="Enter port (e.g., 2525)"
          name="mtaPort"
        />
      </div>
      <div className="products-body-form__line products-body-form__line--space">
        <Field
          label="Allowed Client IPs"
          width="220px"
          value={settings.newClientIp}
          onChange={(e) => setSettings((prev) => ({ ...prev, newClientIp: e.target.value }))}
          parentClassName="p-r-20"
          placeholder="Enter IP (e.g., 192.168.1.1/32)"
          name="newClientIp"
        />
        <button type="button" className="products-body-form__button" onClick={handleAddClientIp}>
          +
        </button>
      </div>
      <ul className="allowed-client-ips-list" style={{ gridColumn: "span 2" }}>
        {settings.allowedClientIps?.map((ip, index) => (
          <li key={index} className="allowed-client-ip-item">
            {ip}
            <button
              type="button"
              style={{ display: "flex" }}
              onClick={() => handleRemoveClientIp(index)}>
              <TrashBucketIcon width="16" height="16" />
              <p style={{ marginLeft: "5px" }}>Delete</p>
            </button>
          </li>
        ))}
      </ul>
      <div className="products-body-form__line products-body-form__line--space">
        <Field
          label="RCPT Domain"
          width="220px"
          value={settings.newDomain?.domain}
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              newDomain: { ...prev.newDomain, domain: e.target.value },
            }))
          }
          parentClassName="p-r-20"
          placeholder="Domain (e.g., example.com)"
          name="newDomainDomain"
        />
        <Field
          label="Relay"
          width="220px"
          value={settings.newDomain?.relay}
          onChange={(e) =>
            setSettings((prev) => ({
              ...prev,
              newDomain: { ...prev.newDomain, relay: e.target.value },
            }))
          }
          parentClassName="p-r-20"
          placeholder="Relay (e.g., [192.168.1.142]:25)"
          name="newDomainRelay"
        />
        <button type="button" className="products-body-form__button" onClick={handleAddDomain}>
          +
        </button>
      </div>
      <ul className="rcpt-domains-list" style={{ gridColumn: "span 2" }}>
        {settings.rcptDomains?.map((item, index) => (
          <li key={index} className="rcpt-domain-item">
            {item.domain} - {item.relay}
            <button
              type="button"
              style={{ display: "flex" }}
              onClick={() => handleRemoveDomain(index)}>
              <TrashBucketIcon width="16" height="16" />
              <p style={{ marginLeft: "5px" }}>Delete</p>
            </button>
          </li>
        ))}
      </ul>
      <button type="button" className="save-button primary-button" onClick={handleSave}>
        Save Settings
      </button>
    </form>
  );
};

export default MailTransferForm;
