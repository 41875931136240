import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "../../../Components/common/TextField/TextField";
import { Link } from "react-router-dom";
import CardForm from "../../../Components/common/CardForm/CardForm";
import Terms from "../Terms/Terms";
import ReCAPTCHA from "react-google-recaptcha";
import { cloudCreateUser } from "../../../store/sagas/cloudSagas";

const SignUp = () => {
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    username: "",
  });
  const [errors, setErrors] = useState({});
  const [showTerms, setShowTerms] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const dispatch = useDispatch();

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!inputs.email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputs.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!inputs.username) {
      newErrors.username = "Username is required";
    } else if (inputs.username.length < 3) {
      newErrors.username = "Username must be at least 3 characters";
    }

    if (!inputs.password) {
      newErrors.password = "Password is required";
    } else if (inputs.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    } else if (!/[A-Z]/.test(inputs.password)) {
      newErrors.password = "Password must contain at least one uppercase letter";
    } else if (!/[a-z]/.test(inputs.password)) {
      newErrors.password = "Password must contain at least one lowercase letter";
    } else if (!/[0-9]/.test(inputs.password)) {
      newErrors.password = "Password must contain at least one number";
    } else if (!/[@$!%*?&#]/.test(inputs.password)) {
      newErrors.password =
        "Password must contain at least one special character (@, $, !, %, *, ?, &, #)";
    }

    if (!inputs.confirmPassword) {
      newErrors.confirmPassword = "Confirm Password is required";
    } else if (inputs.password !== inputs.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (!captchaToken) {
      newErrors.captcha = "Please complete the CAPTCHA verification";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const user = {
        username: inputs.username,
        email: inputs.email,
        password: inputs.password,
        captcha_token: captchaToken,
      };

      const userSpace = {
        userValue: user,
        role: "user",
      };

      dispatch(cloudCreateUser(userSpace));
      console.log("Form submitted successfully", inputs);
    }
  };

  const closeModal = (e) => {
    if (e.target.className === "modal") {
      setShowTerms(false);
    }
  };

  return (
    <CardForm
      error={Object.keys(errors).length > 0}
      title="Free Register"
      subTitle="Get your free CyberFortress account now."
      errorText={
        Object.keys(errors).length > 0 ? (
          <div>
            {Object.values(errors).map((error, index) => (
              <p key={index} style={{ color: "red" }}>
                - {error}
              </p>
            ))}
          </div>
        ) : (
          ""
        )
      }>
      <TextField
        placeholder="Enter your email"
        value={inputs.email}
        onChange={handleChanges}
        labelText="Email"
        name="email"
        required={true}
        error={!!errors.email}
        helperText={errors.email}
      />
      <TextField
        placeholder="Enter your username"
        value={inputs.username}
        onChange={handleChanges}
        labelText="Username"
        name="username"
        required={true}
        error={!!errors.username}
        helperText={errors.username}
      />
      <TextField
        placeholder="Enter your password"
        value={inputs.password}
        onChange={handleChanges}
        labelText="Password"
        name="password"
        required={true}
        error={!!errors.password}
        helperText={errors.password}
        type="password"
      />
      <TextField
        placeholder="Confirm your password"
        value={inputs.confirmPassword}
        onChange={handleChanges}
        labelText="Confirm Password"
        name="confirmPassword"
        required={true}
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
        type="password"
      />
      <ReCAPTCHA
        sitekey="6Lc6dZAqAAAAAFuTi4WNoanRNlV7SnXBKl9sjQwk"
        onChange={handleCaptchaChange}
        style={{ marginTop: "10px" }}
        theme="dark"
      />
      <button className="card__form-btn" onClick={handleSubmit}>
        Sign Up
      </button>
      <p>
        By registering you agree to the CyberFortress
        <span style={{ color: "blue", cursor: "pointer" }} onClick={() => setShowTerms(true)}>
          Terms of Use
        </span>
      </p>
      <p>
        Already have an account? <Link to="/login">Login</Link>
      </p>
      <p>
        <Link to="/forgot-password">Forgot password ?</Link>
      </p>

      {showTerms && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <button className="modal-close" onClick={() => setShowTerms(false)}>
              &times;
            </button>
            <Terms />
          </div>
        </div>
      )}
    </CardForm>
  );
};

export default SignUp;
