import React, { useState, useEffect } from "react";
import TextField from "../../../Components/common/TextField/TextField";
import { Link } from "react-router-dom";
import CheckBox from "../../../Components/common/CheckBox/CheckBox";
import CardForm from "../../../Components/common/CardForm/CardForm";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../../store/actions/usersActions";
import Backdrop from "../../../Components/UI/Backdrop/Backdrop";
import { licenceCheckRequest, licenceRegRequest } from "../../../store/actions/usersActions";
import packageJson from "../../../../package.json";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Login = ({ draganddrop = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const error = useSelector((state) => state?.users.loginError);
  const loading = useSelector((state) => state?.users.loginLoading);
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);
  const user = useSelector((state) => state?.users.user);

  useEffect(() => {
    if (user?.role === "ADMIN" || user?.role === "SUPERADMIN") {
      history.push("/dashboard");
    }
  }, [user]);

  useEffect(() => {
    dispatch(licenceCheckRequest());
  }, [dispatch]);

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [checked, setChecked] = useState(false);
  const [inputsKey, setInputsKey] = useState({
    licencekey: "",
  });
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangesKey = (e) => {
    const { name, value } = e.target;
    setInputsKey((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const submitFormHandler = (e) => {
    e.preventDefault();
    if (!captchaToken) {
      alert("Please complete the CAPTCHA verification.");
      return;
    }
    dispatch(loginRequest({ ...inputs, checked }));
  };

  const submitFormHandlerKey = (e) => {
    e.preventDefault();
    dispatch(licenceRegRequest(inputsKey.licencekey));
  };

  return licenceCheck?.theme ? (
    licenceCheck?.theme && (
      <CardForm
        error={error}
        title="Welcome Back!"
        subTitle={
          "Sign in to continue to " +
          (licenceCheck?.theme === "dataflowx" ? "Control Panel" : "CyberFortress.")
        }
        errorText=" Invalid username or password, please try again.">
        <form onSubmit={submitFormHandler}>
          <TextField
            placeholder="Enter your email"
            value={inputs.email}
            onChange={handleChanges}
            labelText="Email"
            name="email"
            required={true}
            error={error}
          />
          <TextField
            placeholder="Enter your password"
            value={inputs.password}
            onChange={handleChanges}
            labelText="Password"
            name="password"
            required={true}
            error={error}
            type="password"
          />
          <CheckBox checked={checked} setChecked={setChecked} text={"Remember me"} />
          <ReCAPTCHA
            sitekey="6Lc6dZAqAAAAAFuTi4WNoanRNlV7SnXBKl9sjQwk"
            onChange={handleCaptchaChange}
            style={{ marginTop: "10px" }}
            theme="dark"
          />
          <button className="card__form-btn" type="submit">
            Sign In
          </button>
          <p style={{ textAlign: "center" }}>
            New? <Link to="/signup">Join Us</Link>
          </p>
          <p style={{ textAlign: "center" }}>
            <Link to="/forgot-password">Forgot password ?</Link>
          </p>
          <p style={{ textAlign: "center" }}>v{packageJson.version}</p>
        </form>
        {loading && <Backdrop show={loading} />}
      </CardForm>
    )
  ) : (
    <CardForm
      error={error}
      title="Welcome!"
      subTitle="Please enter the license key you received from our team in the box below."
      errorText=" Invalid username or password, please try again.">
      <form onSubmit={submitFormHandlerKey}>
        <TextField
          placeholder="Enter your licence key"
          value={inputsKey.licencekey}
          onChange={handleChangesKey}
          labelText="Licence Key"
          name="licencekey"
          required={true}
          error={error}
        />
        <button className="card__form-btn" type="submit">
          Submit
        </button>
      </form>
      {loading && <Backdrop show={loading} />}
    </CardForm>
  );
};

export default Login;
