import React from "react";
import "./styles.scss";
import LogoIcon from "../../../Components/Icon-SVG/LogoIcon";

const CloudFooter = () => {
  return (
    <div className="footer-cl">
      <div className="footer-cl__inner">
        <a
          className="footer-cl__inner__link"
          href="https://cyber-fortress.com/"
          target="_blank"
          rel="noreferrer">
          <div className="footer-cl__logo">
            <div className="footer-cl__logo__icon" style={{ marginTop: "5px" }}>
              <LogoIcon width={33} height={33} color="#1082b4" />
            </div>
            <div className="footer-cl__logo__text">
              <strong className="footer-cl__logo__text-cyber">Cyber</strong>
              <strong className="footer-cl__logo__text-fortress">Fortress</strong>
            </div>
          </div>
        </a>
        <span className="footer-cl__inner__pipe"> | </span>
        <p className="footer-cl__inner__text">Security Professionals</p>
      </div>
    </div>
  );
};

export default CloudFooter;
