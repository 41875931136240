const BlockedEmailsDate = (props) => {
  let displayedDate = "-";

  const time = props?.row?.original?.date;
  const timeStr = time.$date || time;

  try {
    const splittedDate = timeStr.split("T");
    const ymd = splittedDate[0] || "-";
    const hms = splittedDate[1].split(".")[0] || "-";
    displayedDate = `${ymd} (${hms})`;
  } catch (e) {}

  return <span>{displayedDate}</span>;
};

export default BlockedEmailsDate;
