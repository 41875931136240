export const prepareSubmitData = (selectedFiles, vmOptions) => {
  const result = [];

  selectedFiles.forEach((selectedFile) => {
    const newObj = {};
    vmOptions.forEach((vm) => {
      if (selectedFile[vm.name]) {
        const fields = Object.keys(selectedFile[vm.name]);
        fields.forEach((field) => {
          if (newObj[field]) {
            newObj[field].push(selectedFile[vm.name][field]);
          } else {
            newObj[field] = [selectedFile[vm.name][field]];
          }
        });
        const { file, fileFormat, fileHash, fileName, fileIDfull, source } = selectedFile;
        if (file) newObj.file = file;
        if (fileFormat) newObj.fileType = fileFormat;
        if (fileHash) newObj.fileHash = fileHash;
        if (fileName) newObj.fileName = fileName;
        if (fileIDfull) newObj.fileIDfull = fileIDfull;
        if (source) newObj.source = source;
      }
    });
    result.push(newObj);
  });

  result.forEach((el) => {
    if (el.machine.length === 1) {
      el.type = el.type[0];
      el.network_traffic = el.network_traffic[0];
    }
  });

  return result;
};
