import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileCardButton from "../../../../../UI/FileCardButton/FileCardButton";
import Modal from "../../../../../UI/Modal/Modal";
import CheckRingRoundDuotone from "../../../../../Icon-SVG/CheckRingRoundDuotone";
import SubmitPopup from "../../../../../common/SubmitPopup/SubmitPopup";
import Warning from "../../../../../common/Warning/Warning";
import Content from "../../../../../common/SubmitPopup/Content/Content";
import { desktopVmOptions } from "../../../../../common/SubmitPopup/constants";
import { RequestNewFile } from "../../../../../../store/actions/dashboardActions";
import { prepareSubmitData } from "../../../../../common/SubmitPopup/utils";

const Advanced = ({ selectedFiles, setSelectedFiles }) => {
  const dispatch = useDispatch();

  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");

  const vms = useSelector((state) => state.dashboard.vms);

  const emptyFile = () => {
    setActive(false);
    setError("Please select at least one file");
    setShow(true);
  };

  const noVm = () => {
    if (desktopVmOptions.some((vmOption) => vms.includes(vmOption.id))) return;

    setActive(false);
    setError("No vm available. Refresh the page and try again.");
    setShow(true);
  };

  const closePopupHandler = () => {
    setActive(false);
  };

  const submitHandler = (selectedFiles) => {
    const preparedSubmitData = prepareSubmitData(selectedFiles, desktopVmOptions);
    dispatch(RequestNewFile(preparedSubmitData));
  };

  const renderContent = (
    currentFileIndex,
    activeOs,
    selectedFiles,
    checkboxInputChangeHandler,
    enforceRuntimeChangeHandler,
    otherInputsChangeHandler,
  ) => (
    <Content
      currentFileIndex={currentFileIndex}
      activeOs={activeOs}
      selectedFiles={selectedFiles}
      onCheckboxInputChange={checkboxInputChangeHandler}
      onEnforceRuntimeChange={enforceRuntimeChangeHandler}
      onOtherInputsChange={otherInputsChangeHandler}
    />
  );

  return (
    <>
      <FileCardButton setActive={setActive}>
        <CheckRingRoundDuotone />
        <p>Advanced</p>
      </FileCardButton>
      {active ? (selectedFiles.length === 0 ? emptyFile() : null) : null}
      {active ? noVm() : null}
      {active && (
        <Modal active={active} setActive={setActive} type="submit-popup">
          <SubmitPopup
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            vmOptions={desktopVmOptions.filter((vmOption) => vms.includes(vmOption.id))}
            onClose={closePopupHandler}
            onSubmit={submitHandler}
            renderContent={renderContent}
          />
        </Modal>
      )}

      <Warning
        onClickOk={() => setShow(false)}
        show={show}
        setShow={setShow}
        text={error}
        article="Warning"
        cancelDisable={true}
        textOK="Okay"
      />
    </>
  );
};

export default Advanced;
