import * as React from "react";
const SupportIcon = ({ color = "#32373C", width = 12, height = 12 }) => (
  <svg
    fill={color}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <g data-name="Layer 2">
      <g data-name="menu-arrow-circle">
        <rect width={24} height={24} transform="rotate(180 12 12)" opacity={0} />
        <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z" />
        <path d="M12 6a3.5 3.5 0 0 0-3.5 3.5 1 1 0 0 0 2 0A1.5 1.5 0 1 1 12 11a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.16A3.49 3.49 0 0 0 12 6z" />
        <circle cx={12} cy={17} r={1} />
      </g>
    </g>
  </svg>
);
export default SupportIcon;
