import { useState } from "react";

import ProductsBody from "./content/ProductsBody";
import ProductsSidebar from "./content/ProductsSidebar";

import "./styles.scss";

const PrProducts = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedHost, setSelectedHost] = useState(null);

  const selectItemHandler = (product, host) => {
    setSelectedProduct(product);
    setSelectedHost(host);
  };

  return (
    <div className="products-container">
      <h1 className="products-container__header">Products</h1>
      <div className="products">
        <ProductsSidebar onItemSelect={selectItemHandler} selectedHost={selectedHost} />
        <ProductsBody selectedProduct={selectedProduct} selectedHost={selectedHost} />
      </div>
    </div>
  );
};

export default PrProducts;
