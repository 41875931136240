import axiosApi from "../../utils/axios";
import { put, takeEvery } from "redux-saga/effects";
import {
  cloudCreateUserFailure,
  cloudCreateUserRequest,
  cloudCreateUserSuccess,
} from "../actions/cloudActions";
import { customFormData } from "../../utils/customFormData";
import { addNotification } from "../actions/notifierActions";

export function* cloudCreateUser({ payload: data }) {
  const formData = customFormData(data.userValue);
  var response = "";

  try {
    response = yield axiosApi.post("/api/cloud/signup", formData);

    yield put(cloudCreateUserSuccess());
    if (response?.data.message === "email exists") {
      yield put(
        addNotification({
          message: "User with this email already exists.",
          options: { variant: "error" },
        }),
      );
    } else if (response?.data.message === "username exists") {
      yield put(
        addNotification({
          message: "User with this username already exists.",
          options: { variant: "error" },
        }),
      );
    } else {
      yield put(
        addNotification({ message: response?.data.message, options: { variant: "success" } }),
      );
    }
  } catch (error) {
    yield put(cloudCreateUserFailure(error));
    yield put(
      addNotification({ message: error?.response?.data?.message, options: { variant: "error" } }),
    );
  }
}

const cloudSagas = [takeEvery(cloudCreateUserRequest, cloudCreateUser)];

export default cloudSagas;
