import { useDispatch, useSelector } from "react-redux";

import Spinner from "../../../../common/Spinner/Spinner";

import { RequestReleaseEmail } from "../../../../../store/actions/mailGatewayActions";

const BlockedEmailsRelease = (props) => {
  const action = props?.row?.original?.action || "-";
  const emailId = props.row.original?.email_id || "-";
  const host = props.row.original?.host || "-";
  const reason = props.row.original?.reason || "-";

  const loading = useSelector((state) => state.mailGateway.releaseEmailLoading);
  const releaseEmailId = useSelector((state) => state.mailGateway.releaseEmailId);

  const isLoading = loading && emailId === releaseEmailId;

  const dispatch = useDispatch();

  if (reason === "Processing") {
    return (
      <button className="disabled" disabled>
        Manual
      </button>
    );
  }

  if (action === "Released") {
    return (
      <button className="disabled" disabled>
        Release
      </button>
    );
  }

  const releaseHandler = (e) => {
    e.stopPropagation();
    if (emailId === "-" || host === "-") {
      return;
    }

    dispatch(RequestReleaseEmail({ emailId, host }));
  };

  if (action === "Block" || action === "Hold") {
    return (
      <button onClick={releaseHandler}>
        {isLoading ? <Spinner width={12} height={12} /> : "Release"}
      </button>
    );
  }
};

export default BlockedEmailsRelease;
