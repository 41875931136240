import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TabButton from "../TabButton/TabButton";
import ClipIcon from "../../Icon-SVG/ClipIcon";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import ToggleButton from "../ToggleButton/ToggleButton";
import CloseButton from "../../UI/CloseButton/CloseButton";
import InformationPopup from "../InformationPopup/InformationPopup";
import { informationPopupContent } from "../InformationPopup/utils/informationPopupUtil";
import { truncateString } from "../../../utils/function";

import { addNotification } from "../../../store/actions/notifierActions";

import "./styles.scss";

const truncateArtifactFileName = (stringToTruncate, maxLength) =>
  stringToTruncate.length > maxLength
    ? stringToTruncate.slice(0, maxLength) + "..."
    : stringToTruncate;

const SubmitPopup = ({
  selectedFiles,
  setSelectedFiles,
  onSubmit = () => {},
  onClose = () => {},
  vmOptions = [],
  showApplyAll = true,
  showFileList = true,
  renderContent = () => {},
}) => {
  const dispatch = useDispatch();

  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [activeOs, setActiveOs] = useState(vmOptions[0]?.name);

  const vms = useSelector((state) => state.dashboard.vms);
  const licenceCheck = useSelector((state) => state?.users.licenceCheck);

  useEffect(() => {
    const updatedSelectedFiles = selectedFiles.map((selectedFile) => {
      const firstTimeSelected = vmOptions.every((vm) => !selectedFile[vm.name]);
      if (firstTimeSelected) {
        const updatedSelectedFile = {
          file: selectedFile?.file,
          fileFormat: selectedFile?.file?.type,
          fileName: selectedFile?.fileName,
          fileHash: selectedFile?.fileHash,
          fileIDfull: selectedFile?.fileIDfull,
          source: selectedFile?.source,
          [vmOptions[0].name]: {},
        };
        const entries = Object.entries(vmOptions[0].defaults);
        entries.forEach((entry) => {
          const [key, value] = entry;
          updatedSelectedFile[vmOptions[0].name][key] = value;
        });
        return updatedSelectedFile;
      }
      return selectedFile;
    });
    setSelectedFiles(updatedSelectedFiles);
  }, [setSelectedFiles, currentFileIndex]);

  const applyAllHandler = () => {
    const selectedFile = selectedFiles[currentFileIndex];
    const { file, fileFormat, ...optionsToApplyAll } = selectedFile;
    const updatedSelectedFiles = selectedFiles.map((selectedFile, i) => {
      if (i === currentFileIndex) return selectedFile;
      else return { ...selectedFile, ...optionsToApplyAll };
    });
    setSelectedFiles(updatedSelectedFiles);
  };

  const selectFileHandler = (index) => {
    setCurrentFileIndex(index);
  };

  const unSelectFileHandler = (index) => {
    if (currentFileIndex === selectedFiles.length - 1) {
      const currentIndex = currentFileIndex - 1;
      setCurrentFileIndex(currentIndex);
    }
    setSelectedFiles((selectedFiles) => selectedFiles.filter((_, i) => i !== index));
  };

  const selectVmHandler = (vm) => {
    const selectedFile = selectedFiles[currentFileIndex];
    if (selectedFile[vm]) {
      delete selectedFile[vm];
    } else {
      selectedFile[vm] = {};
      const vmObj = vmOptions.filter((el) => el.name === vm)[0];
      const entries = Object.entries(vmObj.defaults);
      entries.forEach((entry) => {
        const [key, value] = entry;
        selectedFile[vm][key] = value;
      });
    }
    // Don't let user to unselect all vm options. If so, select the first vm option
    if (!vmOptions.some((vmOption) => selectedFile[vmOption.name])) {
      selectedFile[vmOptions[0].name] = {};
      const vmObj = vmOptions[0];
      const entries = Object.entries(vmObj.defaults);
      entries.forEach((entry) => {
        const [key, value] = entry;
        selectedFile[vmOptions[0].name][key] = value;
      });
    }
    const updatedSelectedFile = selectedFile;
    const updatedSelectedFiles = selectedFiles.map((selectedFile, i) =>
      i === currentFileIndex ? updatedSelectedFile : selectedFile,
    );
    setSelectedFiles(updatedSelectedFiles);
  };

  const toggleChangeHandler = (field, oppositeState) => {
    const selectedFile = selectedFiles[currentFileIndex];

    const updatedSelectedFile = {
      ...selectedFile,
      [activeOs]: {
        ...selectedFile[activeOs],
        [field]: oppositeState,
      },
    };

    const updatedSelectedFiles = selectedFiles.map((selectedFile, i) =>
      i === currentFileIndex ? updatedSelectedFile : selectedFile,
    );
    setSelectedFiles(updatedSelectedFiles);
  };

  const checkboxInputChangeHandler = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const selectedFile = selectedFiles[currentFileIndex];
    const currentValueOfField = selectedFile[activeOs][field];
    let newValueOfField = [];

    if (currentValueOfField.includes(value)) {
      newValueOfField = currentValueOfField.filter((nt) => nt !== value);
    } else {
      newValueOfField = [...currentValueOfField, value];
    }

    if (newValueOfField.length === 0) {
      const vmObj = vmOptions.filter((el) => el.name === activeOs)[0];
      newValueOfField = vmObj.defaults[field];
    }

    const updatedSelectedFile = {
      ...selectedFile,
      [activeOs]: {
        ...selectedFile[activeOs],
        [field]: newValueOfField,
      },
    };

    const updatedSelectedFiles = selectedFiles.map((selectedFile, i) =>
      i === currentFileIndex ? updatedSelectedFile : selectedFile,
    );
    setSelectedFiles(updatedSelectedFiles);
  };

  const otherInputsChangeHandler = (e) => {
    const field = e.target.name;
    const value = field === "runtime_duration" ? Number(e.target.value) : e.target.value;
    const selectedFile = selectedFiles[currentFileIndex];

    const updatedSelectedFile = {
      ...selectedFile,
      [activeOs]: {
        ...selectedFile[activeOs],
        [field]: value,
      },
    };

    const updatedSelectedFiles = selectedFiles.map((selectedFile, i) =>
      i === currentFileIndex ? updatedSelectedFile : selectedFile,
    );
    setSelectedFiles(updatedSelectedFiles);
  };

  const forceSubmitHandler = (oppositeState) => {
    toggleChangeHandler("enforse_submission", oppositeState);
  };

  const enforceRuntimeChangeHandler = (oppositeState) => {
    toggleChangeHandler("enforse_runtime", oppositeState);
  };

  const submitHandler = () => {
    if (licenceCheck.licenceStatus === "Unavailable") {
      dispatch(
        addNotification({
          message: "Your license key has expired. Please contact CyberFortress Technology Team.",
          options: { variant: "error" },
        }),
      );
    } else {
      onSubmit(selectedFiles);
      setSelectedFiles([]);
      onClose();
    }
  };

  return (
    <div className="submit-popup">
      <CustomScrollbar maxHeight={"90vh"}>
        <div className="submit-popup-container">
          <div className="submit-popup-container__close-button">
            <CloseButton onClick={onClose} />
          </div>
          {showApplyAll ? (
            <div className="submit-popup-container__buttons__container--apply">
              <button onClick={applyAllHandler}>Apply All</button>
            </div>
          ) : null}
          <div
            className="submit-popup-container__chosen-files-data"
            style={{ paddingTop: "10px", marginBottom: "10px" }}>
            <div
              className="submit-popup-container__chosen-files-data__clip"
              style={{ position: "absolute" }}>
              <ClipIcon width="24" height="24" />
            </div>
            <p className="light" style={{ paddingTop: "5px", paddingLeft: "30px" }}>
              {showFileList
                ? `Chosen File: ${truncateString(selectedFiles[currentFileIndex].file.name, 22)}`
                : `File: ${truncateArtifactFileName(selectedFiles[currentFileIndex].fileName, 37)}`}
            </p>
          </div>
          {showFileList ? (
            <section className="submit-popup-container__choosen-files-block">
              {selectedFiles.map((selectedFile, i) => (
                <div className="submit-popup-container__choosen-files-item" key={i}>
                  <p>
                    <b
                      className="submit-popup-container__choosen-files-item__icon"
                      onClick={() => unSelectFileHandler(i)}>
                      <b className={"submit-popup-container__choosen-files-item__icon__active"}>
                        X
                      </b>
                    </b>
                    <b
                      className="submit-popup-container__choosen-files-item__file"
                      style={{ paddingLeft: "20px", margin: "auto" }}>
                      <button onClick={() => selectFileHandler(i)}>
                        {truncateString(selectedFile.file.name, 35)}
                      </button>
                    </b>
                  </p>
                </div>
              ))}
            </section>
          ) : null}
          <section className="submit-popup-container__vm" style={{ marginTop: "5px" }}>
            <p className="light margin-top" style={{ display: "flex" }}>
              Select VMS
            </p>
            <div className="submit-popup-container__vm--labels m-t-45 relative">
              <div className="button-block">
                {vmOptions.map(
                  (vm, i) => (
                    // vms.includes(vm.id) ? (
                    <TabButton
                      key={i}
                      title={vm.name}
                      active={activeOs}
                      onClick={setActiveOs}
                      osActive={!!selectedFiles[currentFileIndex][vm.name]}
                      mobileSubmit={true}
                      onChange={selectVmHandler}
                    />
                  ),
                  // ) : null,
                )}
              </div>
            </div>
          </section>
          {selectedFiles[currentFileIndex][activeOs] ? (
            renderContent(
              currentFileIndex,
              activeOs,
              selectedFiles,
              checkboxInputChangeHandler,
              enforceRuntimeChangeHandler,
              otherInputsChangeHandler,
            )
          ) : (
            <div className="submit-popup-container__content">
              <p className="submit-popup-container__content--text">
                Please tick the "{activeOs}" checkbox.
              </p>
            </div>
          )}
          <span
            className={`submit-popup-container__information-popup submit-popup-container__information-popup--${
              showApplyAll ? "dashboard" : "artifact"
            }`}>
            <InformationPopup
              content={informationPopupContent.submitPopup}
              iconWidth={32}
              iconHeight={32}
            />
          </span>
        </div>
        <section className="submit-popup-container__buttons">
          <div className="submit-popup-container__buttons__container" style={{ marginLeft: "0px" }}>
            <div className="submit-popup-container__buttons__container--force">
              <ToggleButton
                active={selectedFiles[currentFileIndex][activeOs]?.enforse_submission}
                rightText={"Force Submit"}
                onClick={forceSubmitHandler}
              />
            </div>
            <button
              className="submit-popup-container__buttons__container--cancel"
              onClick={onClose}>
              Cancel
            </button>
            <button
              className="submit-popup-container__buttons__container--submit"
              onClick={submitHandler}>
              Submit
            </button>
          </div>
        </section>
      </CustomScrollbar>
    </div>
  );
};
export default SubmitPopup;
