import React from "react";
import "./styles.scss";

const TabButton = ({
  title,
  onClick,
  className = "",
  active,
  tabNumber = 0,
  submitOs = false,
  osActive = false,
  os = "",
  osSettings,
  mobileSubmit = false,
  onChange = () => {},
}) => {
  return (
    <button
      className={`button ${active === title ? "button__active" : ""} ${className}`}
      onClick={() => onClick && onClick(title)}>
      {mobileSubmit ? (
        <input
          type="checkbox"
          value={os}
          name={os}
          className="submit-popup-container__vm--input"
          onChange={() => onChange(title)}
          checked={osActive}
        />
      ) : (
        submitOs && (
          <input
            type="checkbox"
            value={os}
            name={os}
            className="submit-popup-container__vm--input"
            onChange={() => osSettings(!osActive)}
            checked={osActive}
          />
        )
      )}
      {tabNumber === 0 ? null : (
        <div className="tab-number border">{tabNumber > 9 ? "9+" : tabNumber}</div>
      )}
      {title}
    </button>
  );
};

export default TabButton;
