import React from "react";
import CloudHeader from "../CloudHeader/CloudHeader";

import "./styles.scss";

const CloudLayout = ({ children }) => {
  return (
    <div className="layout-cl">
      <div
        className="main-block-cl"
        style={{
          width: "100%",
        }}>
        <header className="header-cl" style={{ width: "100%" }}>
          <CloudHeader />
        </header>
        <main className="content-cl">
          {children}
          <div id="scroll-target-cl"></div>
        </main>
      </div>
    </div>
  );
};
export default CloudLayout;
