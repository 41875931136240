import { createSlice } from "@reduxjs/toolkit";

export const name = "mailGateway";

export const mailGatewaySlice = createSlice({
  name,
  initialState: {
    distinctHosts: [],
    distinctHostsLoading: false,
    distinctLiveHosts: [],
    distinctLiveHostsLoading: false,
    analyzedEmails: [],
    analyzedEmailsSubmissions: [],
    analyzedEmailsLoading: false,
    blockedEmails: [],
    blockedEmailsSubmissions: [],
    blockedEmailsLoading: false,
    releaseEmailId: "",
    releaseEmailLoading: false,
    mailTransferSettings: {},
    mailTransferSettingsLoading: false,
    whitelistSettings: {},
    whitelistSettingsLoading: false,
  },
  reducers: {
    RequestDistinctHosts: (state) => {
      state.distinctHostsLoading = true;
    },
    SuccessDistinctHosts: (state, action) => {
      state.distinctHosts = action.payload;
      state.distinctHostsLoading = false;
    },
    FailureDistinctHosts: (state) => {
      state.distinctHosts = [];
      state.distinctHostsLoading = false;
    },
    RequestDistinctLiveHosts: (state) => {
      state.distinctLiveHostsLoading = true;
    },
    SuccessDistinctLiveHosts: (state, action) => {
      state.distinctLiveHosts = action.payload;
      state.distinctLiveHostsLoading = false;
    },
    FailureDistinctLiveHosts: (state) => {
      state.distinctLiveHosts = [];
      state.distinctLiveHostsLoading = false;
    },
    RequestAnalyzedEmails: (state) => {
      state.analyzedEmailsLoading = true;
    },
    SuccessAnalyzedEmails: (state, action) => {
      const { analyzed_emails, sbox_submissions } = action.payload;
      state.analyzedEmails = analyzed_emails;
      state.analyzedEmailsSubmissions = sbox_submissions;
      state.analyzedEmailsLoading = false;
    },
    FailureAnalyzedEmails: (state) => {
      state.analyzedEmailsLoading = false;
    },
    RequestBlockedEmails: (state) => {
      state.blockedEmailsLoading = true;
    },
    SuccessBlockedEmails: (state, action) => {
      const { blocked_emails, sbox_submissions } = action.payload;
      state.blockedEmails = blocked_emails;
      state.blockedEmailsSubmissions = sbox_submissions;
      state.blockedEmailsLoading = false;
    },
    FailureBlockedEmails: (state) => {
      state.blockedEmailsLoading = false;
    },
    RequestReleaseEmail: (state, action) => {
      state.releaseEmailId = action.payload.emailId;
      state.releaseEmailLoading = true;
    },
    SuccessReleaseEmail: (state) => {
      state.releaseEmailId = "";
      state.releaseEmailLoading = false;
    },
    FailureReleaseEmail: (state) => {
      state.releaseEmailId = "";
      state.releaseEmailLoading = false;
    },
    RequestSetMailTransferSettings: (state) => {
      state.mailTransferSettingsLoading = true;
    },
    SuccessSetMailTransferSettings: (state, action) => {
      state.mailTransferSettings = action.payload;
      state.mailTransferSettingsLoading = false;
    },
    FailureSetMailTransferSettings: (state) => {
      state.mailTransferSettingsLoading = false;
    },
    RequestGetMailTransferSettings: (state) => {
      state.mailTransferSettingsLoading = true;
    },
    SuccessGetMailTransferSettings: (state, action) => {
      state.mailTransferSettings = action.payload;
      state.mailTransferSettingsLoading = false;
    },
    FailureGetMailTransferSettings: (state) => {
      state.mailTransferSettingsLoading = false;
    },
    RequestGetWhitelistSettings: (state) => {
      state.whitelistSettingsLoading = true;
    },
    SuccessGetWhitelistSettings: (state, action) => {
      state.whitelistSettings = action.payload;
      state.whitelistSettingsLoading = false;
    },
    FailureGetWhitelistSettings: (state) => {
      state.whitelistSettingsLoading = false;
    },
    RequestSetWhitelistSettings: (state) => {
      state.whitelistSettingsLoading = true;
    },
    SuccessSetWhitelistSettings: (state) => {
      state.whitelistSettingsLoading = false;
    },
    FailureSetWhitelistSettings: (state) => {
      state.whitelistSettingsLoading = false;
    },
  },
});

export default mailGatewaySlice;
