export const desktopVmOptions = [
  {
    id: "win10",
    name: "Windows 10",
    defaults: {
      type: ["full_triage"],
      command_line: "",
      enviromental_variable: "",
      related_password: "",
      runtime_duration: 120,
      network_traffic: ["vpn"],
      enforse_runtime: false,
      enforse_submission: false,
      memory_dump: "advanced",
      action_script: "default",
      machine: "win10",
    },
  },
  {
    id: "win7",
    name: "Windows 7",
    defaults: {
      type: ["high_evasion"],
      command_line: "",
      enviromental_variable: "",
      related_password: "",
      runtime_duration: 120,
      network_traffic: ["simulate"],
      enforse_runtime: false,
      enforse_submission: false,
      memory_dump: "advanced",
      action_script: "default",
      machine: "win7",
    },
  },
  {
    id: "win11",
    name: "Windows 11",
    defaults: {
      type: ["full_triage"],
      command_line: "",
      enviromental_variable: "",
      related_password: "",
      runtime_duration: 120,
      network_traffic: ["vpn"],
      enforse_runtime: false,
      enforse_submission: false,
      memory_dump: "advanced",
      action_script: "default",
      machine: "win11",
    },
  },
  {
    id: "linux22",
    name: "Linux",
    defaults: {
      type: ["full_triage"],
      command_line: "",
      enviromental_variable: "",
      related_password: "",
      runtime_duration: 120,
      network_traffic: ["vpn"],
      enforse_runtime: false,
      enforse_submission: false,
      memory_dump: "advanced",
      action_script: "default",
      machine: "linux22",
    },
  },
];

export const mobileVmOptions = [
  {
    id: "andro13",
    name: "Android 13",
    defaults: {
      type: ["full_triage"],
      command_line: "",
      enviromental_variable: "",
      related_password: "",
      runtime_duration: 120,
      network_traffic: ["vpn"],
      enforse_runtime: false,
      enforse_submission: false,
      memory_dump: "advanced",
      action_script: "default",
      machine: "andro13",
    },
  },
  // {
  //   id: "andro14",
  //   name: "Android 14",
  //   defaults: {
  //     type: ["full_triage"],
  //     command_line: "",
  //     enviromental_variable: "",
  //     related_password: "",
  //     runtime_duration: 120,
  //     network_traffic: ["internet"],
  //     enforse_runtime: false,
  //     enforse_submission: false,
  //     memory_dump: "advanced",
  //     action_script: "default",
  //     machine: "andro14",
  //   },
  // },
];

export const typeOptions = [
  { display: "Full Triage", value: "full_triage" },
  { display: "High Evasion", value: "high_evasion" },
  { display: "Basic Evasion", value: "basic_evasion" },
];

export const artifactAnalysisOptions = [
  { display: "Basic", value: "basic" },
  { display: "Advanced", value: "advanced" },
  { display: "Full", value: "full" },
];

export const networkTrafficOptions = [
  { display: "Internet", value: "internet" },
  { display: "VPN", value: "vpn" },
  { display: "Simulate", value: "simulate" },
  { display: "Disable", value: "disable" },
];
