const BlockedEmailIcon = ({ width = 18, height = 18, color = "var(--text-color-card)" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}>
      <path
        fill={color}
        d="M18.5,11c7.269-.231,7.267-10.77,0-11C11.231.231,11.233,10.77,18.5,11Zm0-2a3.449,3.449,0,0,1-1.652-.434l4.718-4.718A3.5,3.5,0,0,1,18.5,9Zm0-7a3.449,3.449,0,0,1,1.652.434L15.434,7.152A3.5,3.5,0,0,1,18.5,2Zm0,11A7.474,7.474,0,0,0,24,10.587V19a5.006,5.006,0,0,1-5,5H5a5.006,5.006,0,0,1-5-5V8c0-.157.032-.3.046-.458L8.464,15.96a5.008,5.008,0,0,0,7.072,0L18.5,13ZM9.878,14.546.744,5.411A4.986,4.986,0,0,1,5,3h6.436a7.532,7.532,0,0,0,4.64,9.592l-1.954,1.954a3.008,3.008,0,0,1-4.244,0Z"
      />
    </svg>
  );
};

export default BlockedEmailIcon;
