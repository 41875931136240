import QueueIcon from "../../../../Icon-SVG/QueueIcon";

const calculateDecision = (decisions) => {
  if (decisions.includes(2)) {
    return 2;
  } else if (decisions.length > 0 && decisions.every((d) => d === 1)) {
    return 1;
  } else if (decisions.length > 0 && decisions.every((d) => d === 0)) {
    return 0;
  } else if (decisions.includes(99)) {
    return "Running";
  } else {
    return "N/A";
  }
};

const getFlatMalwareNames = (submissions) => {
  let malwareNames = [];
  for (const submission of submissions) {
    if (submission.decision === 2) {
      malwareNames = submission.apt_classes.concat(malwareNames);
    } else {
      malwareNames = malwareNames.concat(submission.apt_classes);
    }
  }
  return malwareNames;
};

const AnalyzedEmailsVerdict = (props) => {
  const isApt = props?.row?.original?.submissions?.some((submission) => submission?.is_apt);
  const malwareNames = getFlatMalwareNames(props?.row?.original?.submissions);
  const decisions = props?.row?.original?.submissions?.map((submission) => submission?.decision);
  const decision = calculateDecision(decisions);

  if (decision === "Running") {
    return (
      <div>
        <QueueIcon width={20} height={20} />
      </div>
    );
  } else if (decision === "N/A") {
    return <div className="n-a">N/A</div>;
  } else if (decision === 2) {
    return (
      <div className={isApt ? "apt" : "malicious"}>
        {malwareNames.length > 0 ? `#${malwareNames[0]}` : "Malicious"}
      </div>
    );
  } else if (decision === 1) {
    return <div className="suspicious">Suspicious</div>;
  } else if (decision === 0) {
    return <div className="clean">Not Suspicious</div>;
  }
};

export default AnalyzedEmailsVerdict;
