import React, { useRef, useState, useEffect } from "react";
import NotificationsIcon from "../../../Components/Icon-SVG/NotificationsIcon";
import { useDispatch, useSelector } from "react-redux";
import { RequestGetUILogo } from "../../../store/actions/dashboardActions";
import { logoutRequest } from "../../../store/actions/usersActions";
import Warning from "../../../Components/common/Warning/Warning";
import { useOnClickOutside } from "../../../utils/hook/useOnClickOutside";
import LogoutIcon from "../../../Components/Icon-SVG/LogoutIcon";
import CloudBackdrop from "../CloudBackdrop/CloudBackdrop";
import "./styles.scss";
import UserIcon from "../../../Components/Icon-SVG/UserIcon";
import CloudMenu from "../CloudMenu/CloudMenu";
import LogoIcon from "../../../Components/Icon-SVG/LogoIcon";
import SearchInputCloud from "../../CloudCommon/SearchInputCloud/SearchInputCloud";
import CloudUploadIcon from "../../../Components/Icon-SVG/CloudUploadIcon";
import CloudListIcon from "../../../Components/Icon-SVG/CloudListIcon";
import CloudPricingIcon from "../../../Components/Icon-SVG/CloudPricingIcon";
import SupportIcon from "../../../Components/Icon-SVG/SupportIcon";
import { Link } from "react-router-dom";

const CloudHeader = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state?.users.user);
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [hoveredProfile, setHoveredProfile] = useState(false);
  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState(false);
  const ref = useRef();
  const usernameRef = useRef(null);
  const [fontSize, setFontSize] = useState("16px");

  useEffect(() => {
    dispatch(RequestGetUILogo());
  }, [dispatch]);

  useEffect(() => {
    if (usernameRef.current) {
      const usernameWidth = usernameRef.current.offsetWidth;
      if (usernameWidth > 100) {
        setFontSize("14px");
      } else {
        setFontSize("16px");
      }
    }
  }, [users?.username]);

  useOnClickOutside(ref, () => setMenu(false));

  const logout = () => {
    setShow(false);
    dispatch(logoutRequest());
  };

  const handleMouseEnter = (iconName) => {
    setHoveredIcon(iconName);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  const handleProfileMouseEnter = () => {
    setHoveredProfile(true);
  };

  const handleProfileMouseLeave = () => {
    setHoveredProfile(false);
  };

  const getColor = (iconName) => {
    return hoveredIcon === iconName ? "var(--sidebar-active-icon)" : "var(--text-color-card)";
  };

  const getProfileColor = () => {
    return hoveredProfile ? "var(--sidebar-active-icon)" : "var(--text-color-card)";
  };

  return (
    <div className="header__inner-cl">
      <div className="header__inner-cl-left">
        <LogoIcon width={35} height={35} color="#1082b4" />
        <nav className="header__nav">
          <Link
            to="/"
            title="Upload Sample"
            className="header__nav-item"
            style={{ display: "flex", alignItems: "center" }}
            onMouseEnter={() => handleMouseEnter("CloudUploadIcon")}
            onMouseLeave={handleMouseLeave}>
            <CloudUploadIcon width={18} height={18} color={getColor("CloudUploadIcon")} />
          </Link>
          <Link
            to="/submission-list"
            title="Submission List"
            className="header__nav-item"
            style={{ display: "flex", alignItems: "center" }}
            onMouseEnter={() => handleMouseEnter("CloudListIcon")}
            onMouseLeave={handleMouseLeave}>
            <CloudListIcon width={20} height={20} color={getColor("CloudListIcon")} />
          </Link>
          <Link
            to="/pricing"
            title="Princing"
            className="header__nav-item"
            style={{ display: "flex", alignItems: "center" }}
            onMouseEnter={() => handleMouseEnter("CloudPricingIcon")}
            onMouseLeave={handleMouseLeave}>
            <CloudPricingIcon width={18} height={18} color={getColor("CloudPricingIcon")} />
          </Link>
          <a
            href="https://cyber-fortress.com/main/contact-us.html"
            title="Contact Us"
            className="header__nav-item"
            style={{ display: "flex", alignItems: "center" }}
            onMouseEnter={() => handleMouseEnter("SupportIcon")}
            onMouseLeave={handleMouseLeave}>
            <SupportIcon width={20} height={20} color={getColor("SupportIcon")} />
          </a>
        </nav>
      </div>
      <SearchInputCloud />
      <div className="header__inner-cl-right">
        {users?.username ? (
          <div
            className="header__profile-cl"
            onClick={() => setMenu(!menu)}
            ref={ref}
            onMouseEnter={handleProfileMouseEnter}
            onMouseLeave={handleProfileMouseLeave}>
            <p ref={usernameRef} className="m-r-15" style={{ fontSize, color: getProfileColor() }}>
              {users.username}
            </p>
            <UserIcon width={15} height={15} color={getProfileColor()} />
            <CloudMenu show={menu} setShow={setShow} username={users.username} />
          </div>
        ) : (
          <div className="header__auth-buttons">
            <Link to="/login" className="btn-sign-in">
              Sign In
            </Link>
            <Link to="/signup" className="btn-sign-up">
              Sign Up
            </Link>
          </div>
        )}
        <Warning
          onClickOk={() => logout()}
          setShow={setShow}
          show={show}
          text="Do you really want to logout?"
          article="Logout"
          icon={
            <LogoutIcon
              width={50}
              height={50}
              color={"var(--text-color-card)"}
              padding="20px 10px 10px 10px"
            />
          }
        />
      </div>
    </div>
  );
};

export default CloudHeader;
