import React from "react";
import "./styles.scss";

const Terms = () => {
  return (
    <div style={{ padding: "20px", maxHeight: "400px", overflowY: "auto" }}>
      <h2>Terms of Use</h2>

      <h3>1. Terms of Acceptance</h3>
      <p>
        These Terms of Service define the conditions for your use of MA360 services. By accessing or
        using MA360 services, you declare that you fully accept these terms. If you do not agree to
        these terms, you should not use our services. Furthermore, these terms may be updated from
        time to time. It is the user's responsibility to stay informed about such updates.
      </p>

      <h3>2. Description of Services</h3>
      <p>
        MA360 offers a cloud-based sandbox service for malware analysis and security. This service
        provides detailed reports by using static and dynamic analysis techniques to assess the
        security of your files. MA360 delivers AI-powered analyses and behavior-based inspections
        for malware detection, offering users an in-depth security evaluation. Additionally, users
        can securely access reports and analysis results through MA360.
      </p>

      <h3>3. Terms of Use</h3>
      <p>
        MA360 services must be used solely for lawful purposes and to ensure personal or
        organizational security. Users are strictly prohibited from using our system for any
        malicious, illegal, or unethical purposes. Users are required to comply with the laws of the
        Republic of Turkey and international legal regulations while using the service. MA360
        reserves the right to restrict or terminate access to the service in cases of suspected
        misuse or abuse.
      </p>

      <h3>4. User Data and Privacy</h3>
      <p>
        Data collected during the use of the service is stored in accordance with our privacy policy
        and is not shared with third parties without user consent. MA360 employs industry-standard
        encryption and security measures to ensure the safety of user data. Users have the right to
        share, store, or permanently delete the results of their analyzed files. Furthermore,
        options are provided for users to control the retention period of their data. For more
        details about our privacy policy, please visit [privacy policy link].
      </p>

      <h3>5. Disclaimer of Liability</h3>
      <p>
        MA360 does not guarantee the absolute accuracy, uninterrupted access, or constant updates of
        the service. Results of analyses conducted on the system may not always be 100% accurate due
        to the limitations of the analysis methods used. Users are responsible for any damages or
        losses resulting from the use of information obtained through the service. MA360 accepts no
        liability for direct or indirect damages under any circumstances.
      </p>

      <h3>6. Service Modifications</h3>
      <p>
        MA360 reserves the right to modify its services, discontinue parts of the service, or cease
        the service entirely at any time. Users will be notified in advance of such changes, which
        will be considered accepted if the user continues to use the service. MA360 may regularly
        add new features or modify existing features to enhance the user experience.
      </p>

      <h3>7. Termination</h3>
      <p>
        In cases where users violate these terms of service, MA360 reserves the right to suspend or
        terminate access to its services. Additionally, users have the option to terminate their
        accounts and associated data upon request. In the event of termination, all user data will
        be deleted from the system and cannot be recovered. MA360 is not obligated to refund any
        fees previously paid by the user in case of termination.
      </p>

      <h3>8. Fees and Payment Terms</h3>
      <p>
        MA360 services may include both free and paid plans. Payment terms and pricing details for
        paid plans are explicitly stated during service use. By making a payment, the user is deemed
        to have accepted these pricing terms. MA360 reserves the right to make changes to its
        pricing, and users will be informed of such changes before they take effect.
      </p>

      <h3>9. Applicable Law</h3>
      <p>
        These Terms of Service will be governed by the laws of the Republic of Turkey. In the event
        of any disputes, the courts and enforcement offices of Istanbul will have jurisdiction. By
        accepting these terms, users agree to act in compliance with Turkish laws.
      </p>

      <h3>10. Contact</h3>
      <p>
        If you have any questions regarding these terms or our services, please contact us via
        [contact information]. We value feedback from our users, and you can reach our support team
        for any questions, suggestions, or complaints. Support requests will be evaluated promptly,
        and you will receive a response as soon as possible.
      </p>
    </div>
  );
};

export default Terms;
