import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import {
  publicKeyCheckRequest,
  publicKeyRegRequest,
  apiLimitterGetRequest,
  apiLimitterSetRequest,
} from "../../../../../store/actions/usersActions";

const PublicApi = () => {
  const userPublicKey = useSelector((state) => state?.users.publicKey);
  const apiLimiter = useSelector((state) => state?.users.apiLimiter);
  const [range, setRange] = useState(100);
  const [tempRange, setTempRange] = useState(100);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(publicKeyCheckRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(apiLimitterGetRequest());
  }, [dispatch]);

  useEffect(() => {
    if (apiLimiter) {
      setRange(apiLimiter);
      setTempRange(apiLimiter);
      setIsLoading(false);
    }
  }, [apiLimiter]);

  const createPublicApiKey = () => {
    dispatch(publicKeyRegRequest());
  };

  const saveRange = () => {
    if (tempRange !== apiLimiter) {
      dispatch(apiLimitterSetRequest(tempRange));
      setRange(tempRange);
    }
  };

  return (
    <div className="publicapi">
      <h1 className="publicapi__header">Public API</h1>
      <div className="publicapi__body">
        <div className="publicapi__body__header">
          <h3>Settings</h3>
          <p>
            On this page, you can view your public API key and adjust your public API usage per
            minute.
          </p>
        </div>
        <div className="publicapi__body__content">
          <div className="publicapi__body__content__row">
            <div className="publicapi__body__content__row__apikey">
              <p className="publicapi__body__content__row__apikey__header">Public API Key</p>
              <div className="publicapi__body__content__row__apikey__labels">
                {userPublicKey ? (
                  userPublicKey
                ) : (
                  <button className="primary-button" onClick={createPublicApiKey}>
                    Create Key
                  </button>
                )}
              </div>
            </div>
          </div>
          <div>
            <p className="light publicapi__body__content__row__range-container-header">
              Public API Limiter ( Per minutes )
            </p>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <div className="publicapi__body__content__row__range-container">
                <div className="publicapi__body__content__row__range-container__data">
                  <div></div>
                  <input
                    type="number"
                    value={tempRange}
                    onChange={(e) => setTempRange(Number(e.target.value))}
                    min="1"
                    max="2000"
                    disabled={isLoading}
                  />
                  <div></div>
                </div>
                <input
                  type="range"
                  max="2000"
                  onChange={(e) => setTempRange(Number(e.target.value))}
                  value={tempRange}
                  disabled={isLoading}
                />
              </div>
            )}
            <button
              className="primary-button"
              style={{ marginTop: "20px" }}
              onClick={saveRange}
              disabled={isLoading}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicApi;
