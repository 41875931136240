const AutoCell = (props) => {
  const accessor = props.column.id;
  const data = props.row.original[accessor];

  return (
    <div className="shrink" title={data}>
      {data}
    </div>
  );
};

export default AutoCell;
