import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import CloudBackdrop from "../../CloudUI/CloudBackdrop/CloudBackdrop";
import Card from "../../../Components/common/Card/Card";
import DashBoardTable from "../../../Components/PagesComponents/Dashboard/DashBoardTable/DashBoardTable";
import DemoPopup from "../../../Components/common/DemoPopup/DemoPopup";
import FileCard from "../../../Components/PagesComponents/Dashboard/FileCard/FileCard";
import ListPopup from "../../../Components/PagesComponents/Dashboard/ListPopup/ListPopup";
import MalwareDistribution from "../../../Components/PagesComponents/Dashboard/MalwareDistribution/MalwareDistribution";
import MalwareStats from "../../../Components/PagesComponents/Dashboard/MalwareStats/MalwareStats";
import SystemHealth from "../../../Components/PagesComponents/Dashboard/SystemHealth/SystemHealth";
import FreeRun from "../../../Components/PagesComponents/Dashboard/FreeRun/FreeRun";

import ChartLineIcon from "../../../Components/Icon-SVG/ChartLineIcon";
import MenIcon from "../../../Components/Icon-SVG/MenIcon";

import {
  RequestDailySubmissions,
  RequestAllSubmissions,
  RequestIntegrationSubmissionCount,
  RequestDailyIntegrationSubmissionCount,
  RequestCleanSubmissionsState,
  RequestSuspiciousSubmissionsState,
  RequestMaliciousSubmissionsState,
  RequestIntegrationSubmissionState,
  RequestVms,
  RequestAptCount,
  RequestMalwareNameCounts,
  RequestIntegrationMalwareNameCounts,
  RequestUniqueSubmissionsCount,
} from "../../../store/actions/dashboardActions";
import { licenceCheckRequest } from "../../../store/actions/usersActions";

import "./styles.scss";
import LogoIcon from "../../../Components/Icon-SVG/LogoIcon";

const selectState = (activeTab, submissionsState, integrationsState) => {
  if (activeTab === "all" || activeTab === "all_daily") {
    return submissionsState;
  } else if (activeTab === "integrations" || activeTab === "integrations_daily") {
    return integrationsState;
  } else {
    return 0;
  }
};

//GET FILE ID IN URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Home = () => {
  const dispatch = useDispatch();
  const allSubmissionsCount = useSelector((state) => state.dashboard.totalSubmission);
  const dailySubmissionsCount = useSelector((state) => state.dashboard.dailyCounter);
  const uniqueSubmissionsCount = useSelector((state) => state.dashboard.uniqueSubmissionsCount);
  const dailyAptCount = useSelector((state) => state.dashboard.dailyAptCounter);
  const integrationSubmissionsCount = useSelector(
    (state) => state.dashboard.integrationSubmissionCount,
  );
  const dailyIntegrationSubmissionsCount = useSelector(
    (state) => state.dashboard.dailyIntegrationSubmissionCount,
  );
  const aptCount = useSelector((state) => state.dashboard.aptCount);
  const submissionsCleanState = useSelector((state) => state.dashboard.cleanState);
  const submissionsCleanCount = useSelector((state) => state.dashboard.cleanCount);
  const submissionsSuspiciousState = useSelector((state) => state.dashboard.suspiciousState);
  const submissionsSuspiciousCount = useSelector((state) => state.dashboard.suspiciousCount);
  const submissionsMaliciousState = useSelector((state) => state.dashboard.maliciousState);
  const submissionsMaliciousCount = useSelector((state) => state.dashboard.maliciousCount);
  const integrationsCleanState = useSelector((state) => state.dashboard.integrationCleanState);
  const integrationsCleanCount = useSelector((state) => state.dashboard.integrationCleanCount);
  const integrationsSuspiciousState = useSelector(
    (state) => state.dashboard.integrationSuspiciousState,
  );
  const integrationsSuspiciousCount = useSelector(
    (state) => state.dashboard.integrationSuspiciousCount,
  );
  const integrationsMaliciousState = useSelector(
    (state) => state.dashboard.integrationMaliciousState,
  );
  const integrationsMaliciousCount = useSelector(
    (state) => state.dashboard.integrationMaliciousCount,
  );
  const submissionsLoading = useSelector((state) => state.dashboard.submissionsLoading);
  const currentFile = useSelector((state) => state.dashboard.currentFile);

  const [showDelete, setShowDelete] = useState(false);
  const [listPopupActive, setListPopupActive] = useState(false);
  const [allSubmissionsActive, setAllSubmissionsActive] = useState("all");
  const [freeRunActive, setFreeRunActive] = useState(false);

  //SET QUERY FOR GETTING DATA ID
  let query = useQuery();

  useEffect(() => {
    dispatch(licenceCheckRequest());
    dispatch(RequestVms());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = () => {
      dispatch(RequestDailySubmissions());
      dispatch(RequestAllSubmissions());
      dispatch(RequestUniqueSubmissionsCount());
      dispatch(RequestIntegrationSubmissionCount("all"));
      dispatch(RequestDailyIntegrationSubmissionCount("all"));
      dispatch(RequestCleanSubmissionsState(allSubmissionsActive));
      dispatch(RequestSuspiciousSubmissionsState(allSubmissionsActive));
      dispatch(RequestMaliciousSubmissionsState(allSubmissionsActive));
      dispatch(
        RequestIntegrationSubmissionState({
          timeFilter: allSubmissionsActive,
          integrationType: "all",
        }),
      );
      dispatch(RequestAptCount());
      dispatch(RequestMalwareNameCounts(allSubmissionsActive));
      dispatch(
        RequestIntegrationMalwareNameCounts({
          timeFilter: allSubmissionsActive,
          integrationType: "all",
        }),
      );
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 15000);

    return () => clearInterval(interval);
  }, [dispatch, allSubmissionsActive]);

  useEffect(() => {
    if (query.get("data") !== null) {
      setListPopupActive(true);
    } else {
      setListPopupActive(false);
    }
  }, [query]);

  return (
    <div className="cloud-home">
      <div className="cloud-home__header">
        <div className="cloud-home__logo">
          <div className="cloud-home__logo__icon">
            <LogoIcon width={93} height={93} color="#1082b4" />
          </div>
          <div className="cloud-home__logo__text">
            <strong className="cloud-home__logo__text-cyber">Cyber</strong>
            <strong className="cloud-home__logo__text-fortress">Fortress</strong>
          </div>
        </div>
        <h2 className="cloud-home__header-text">
          Inspect suspicious files and links to uncover hidden threats. CyberFortress MA360
          streamlines analysis and strengthens your security defenses.
        </h2>
      </div>
      <div className="cloud-home__body">
        <div className="cloud-home__body__item">
          <FileCard />
        </div>
      </div>
      <CloudBackdrop
        absolute={false}
        show={submissionsLoading}
        message={`Uploading file "${currentFile}", please wait...`}
      />
    </div>
  );
};
export default Home;
