import { useState } from "react";

import TabButton from "../../../Components/common/TabButton/TabButton";
import MailTransferForm from "../components/MailTransferForm";
import WhitelistForm from "../components/WhitelistForm";

const Header = ({ selectedProduct, selectedHost }) => {
  return (
    <div className="products-body-header">
      <h3 className="products-body-header__title">
        {selectedProduct} / <span>{selectedHost}</span>
      </h3>
      <p className="products-body-header__description">
        <span> NOTE:</span> Here are some notes about settings to inform user.
      </p>
    </div>
  );
};

const ProductsBody = ({ selectedProduct, selectedHost }) => {
  const [active, setActive] = useState("Mail Transfer");

  if (!selectedHost)
    return <p className="products-no-select">Please select a product and a host.</p>;

  return (
    <div className="products-body">
      <div className="button-block">
        <TabButton title="Mail Transfer" onClick={setActive} active={active} />
        <TabButton title="Whitelist" onClick={setActive} active={active} />
      </div>
      <Header selectedProduct={selectedProduct} selectedHost={selectedHost} />
      {active === "Mail Transfer" ? (
        <MailTransferForm selectedHost={selectedHost} />
      ) : active === "Whitelist" ? (
        <WhitelistForm selectedHost={selectedHost} />
      ) : null}
    </div>
  );
};

export default ProductsBody;
