import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import CustomSelect from "../../Components/common/CustomSelect/CustomSelect";
import CustomTable from "../../Components/common/Table/CustomTable";
import ListPopup from "../../Components/PagesComponents/Dashboard/ListPopup/ListPopup";
import QueueIcon from "../../Components/Icon-SVG/QueueIcon";

import { analyzedMailsHeader } from "../../utils/constants/tableHeaders";
import { filterByDistinctGroupID, selectGroupApt, selectGroupSeverity } from "../../utils/function";

import {
  RequestAnalyzedEmails,
  RequestDistinctHosts,
} from "../../store/actions/mailGatewayActions";

import "./styles.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Verdict = ({ submissions, groupID }) => {
  const decision = selectGroupSeverity(submissions, groupID);
  const { isApt, aptClasses } = selectGroupApt(submissions, groupID);

  if (decision === "malicious") {
    return (
      <div className="verdict-container">
        <div className={`verdict verdict--${isApt ? "apt" : "malicious"}`}>
          {aptClasses.length > 0
            ? aptClasses
                .slice(0, 2)
                .map((className) => (
                  <p key={className}>
                    {className.length > 11 ? `#${className.slice(0, 11)}...` : `#${className}`}
                  </p>
                ))
            : "Malicious"}
        </div>
      </div>
    );
  } else if (decision === "suspicious") {
    return (
      <div className="verdict-container">
        <p className="verdict verdict--suspicious">Suspicious</p>
      </div>
    );
  } else if (decision === "not suspicious") {
    return (
      <div className="verdict-container">
        <p className="verdict verdict--clean">Not Suspicious</p>
      </div>
    );
  } else if (decision === "loading") {
    return (
      <div className="verdict-container">
        <span className="verdict">
          <QueueIcon width={20} height={20} />
        </span>
      </div>
    );
  } else if (decision === "n/a") {
    return (
      <div className="verdict-container">
        <span className="verdict">N/A</span>
      </div>
    );
  }
};

const Details = ({ row, history }) => {
  const submissions = row?.original?.submissions || [];

  const filteredSubmissions = submissions.filter((el, i, arr) =>
    filterByDistinctGroupID(el, i, arr),
  );

  const seeReportHandler = (groupID) => {
    history.push(`/mailgateway/analyzed-emails?data=${groupID}&table=analyzed-emails`);
  };

  return (
    <div className="analyzed-emails__table__submissions">
      {filteredSubmissions.map((submission, i) => (
        <div key={i} className="analyzed-emails__table__submission">
          <p title={submission?.file_name}>
            <span>File Name: </span>
            {submission?.file_name || "-"}
          </p>
          <p>
            <span>File Hash: </span>
            {submission?.file_hash || "-"}
          </p>
          <Verdict submissions={submissions} groupID={submission.groupID} />
          <button onClick={() => seeReportHandler(submission.groupID)}>See Report</button>
        </div>
      ))}
    </div>
  );
};

const AnalyzedEmails = () => {
  const [selectedHost, setSelectedHost] = useState(null);
  const [listPopupActive, setListPopupActive] = useState(false);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState(null);

  const dispatch = useDispatch();

  const distinctHosts = useSelector((state) => state.mailGateway.distinctHosts);
  const distinctHostsLoading = useSelector((state) => state.mailGateway.distinctHostsLoading);
  const analyzedEmails = useSelector((state) => state.mailGateway.analyzedEmails);

  let query = useQuery();

  useEffect(() => {
    if (query.get("data") !== null) {
      setListPopupActive(true);
    } else {
      setListPopupActive(false);
    }
  }, [query]);

  useEffect(() => {
    dispatch(RequestDistinctHosts());
  }, [dispatch]);

  useEffect(() => {
    if (selectedHost?.id && selectedTimeFilter) {
      dispatch(RequestAnalyzedEmails({ host: selectedHost.id, timeFilter: selectedTimeFilter }));
    }
  }, [selectedHost, selectedTimeFilter]);

  return (
    <div className="analyzed-emails">
      <div className="analyzed-emails__header">
        <h1>Analyzed Emails</h1>
        <p>Below list is displaying analyzed emails of selected host:</p>
        <div className="analyzed-emails__header__select">
          <p>Select host: </p>
          <CustomSelect
            data={distinctHosts.length ? distinctHosts : []}
            select={selectedHost}
            setSelected={setSelectedHost}
            width={220}
            disabled={distinctHosts.length === 0}
            placeholder={distinctHostsLoading ? "Fetching data..." : "No data."}
          />
        </div>
      </div>
      <div className="analyzed-emails__table border">
        <CustomTable
          exportFile={true}
          dataTable={analyzedEmails}
          tableName="analyzedEmails"
          column={analyzedMailsHeader}
          leftAction={true}
          paginationButtons={true}
          className=""
          customPageSize={10}
          renderRowSubComponent={Details}
          minHeight="585px"
          minBottom="5px"
          defaultPosition="relative"
          selectedHost={selectedHost?.id}
          filterByTime={true}
          selectedTimeFilter={selectedTimeFilter}
          setSelectedTimeFilter={setSelectedTimeFilter}
          defaultFilterIndex={2}
        />
      </div>
      <ListPopup
        onClickOk={() => setListPopupActive(false)}
        show={listPopupActive}
        groupID={query.get("data")}
        tableType={query.get("table")}
        tableType2="analyzed-emails"
      />
    </div>
  );
};

export default AnalyzedEmails;
