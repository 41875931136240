import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: null,
  rememberMe: false,
};

const name = "users";

const cloudSlice = createSlice({
  name,
  initialState: {
    ...initialState,
    loading: false,
    error: false,
  },
  reducers: {
    cloudCreateUserRequest: (state) => {
      state.loading = true;
    },
    cloudCreateUserSuccess: (state) => {
      state.loading = false;
    },
    cloudCreateUserFailure: (state, payload) => {
      state.loading = false;
      state.error = payload?.payload;
    },
  },
});

export default cloudSlice;
