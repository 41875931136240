import * as React from "react";
const CloudPricingIcon = ({ color = "#32373C", width = 12, height = 12 }) => (
  <svg fill={color} width={width} height={height} viewBox="0 0 52 52" data-name="Layer 1">
    <path
      d="M15.1,2.1a1.63,1.63,0,0,1,1.7,1.7V4.9a1.63,1.63,0,0,1-1.7,1.7H10A3.4,3.4,0,0,0,6.6,9.8V42a3.4,3.4,0,0,0,3.2,3.4H42a3.4,3.4,0,0,0,3.4-3.2V36.9a1.63,1.63,0,0,1,1.7-1.7h1.1a1.63,1.63,0,0,1,1.7,1.7v6.2a6.81,6.81,0,0,1-6.8,6.8H8.9a6.81,6.81,0,0,1-6.8-6.8V8.9A6.81,6.81,0,0,1,8.9,2.1Z"
      fillRule="evenodd"
    />
    <g>
      <path d="M29.7,4.9l8.4.2a4,4,0,0,1,1.8.7h0l3.3,3.3,3.3,3.3a2.41,2.41,0,0,1,.7,1.8h0l.2,8.4a2.64,2.64,0,0,1-.7,1.9h0L32.9,38.4a2.53,2.53,0,0,1-3.6,0h0l-7.6-7.6-7.6-7.6a2.53,2.53,0,0,1,0-3.6h0L27.9,5.7a2.34,2.34,0,0,1,1.8-.8ZM25,18.4l-1.4,1.4a.75.75,0,0,0,0,.9l8.1,8.1a.75.75,0,0,0,.9,0L34,27.4a.75.75,0,0,0,0-.9l-8.1-8.1A.56.56,0,0,0,25,18.4Zm3.8-3.8L27.5,16a.75.75,0,0,0,0,.9L35.6,25a.75.75,0,0,0,.9,0l1.4-1.4a.75.75,0,0,0,0-.9l-8.1-8.1C29.5,14.3,29.2,14.3,28.8,14.6Zm13.1-4.1a2.55,2.55,0,1,0,0,3.6A2.54,2.54,0,0,0,41.9,10.5Z" />
    </g>
  </svg>
);
export default CloudPricingIcon;
