import "./styles.scss";

const CloseButton = ({ onClick, customStyle = {} }) => {
  return (
    <div className="close-button__outer" style={{ float: "unset", ...customStyle }}>
      <div onClick={onClick} className="close-button__inner">
        <label className="close-button__close">Close</label>
      </div>
    </div>
  );
};

export default CloseButton;
